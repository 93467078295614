


import { createBrowserRouter, redirect } from 'react-router-dom'
import { getToken } from '../store/storage'
import Layout from '../page/index'
import LoginPage from '../page/login'
import HomePage from '../page/index/home'
import SchoolProfilePage from '../page/index/department/profile'
import CampusPage from '../page/index/department/campus'
import CollegePage from '../page/index/department/college'
import MajorPage from '../page/index/department/major'

import PlanPage from '../page/index/enrollment/plan'
import ScorePage from '../page/index/enrollment/score'
import AdmissionPage from '../page/index/enrollment/admission'

import ColumnPage from '../page/index/content/column'
import ArticlePage from '../page/index/content/article'
import ImagePage from '../page/index/content/image'
import VideoPage from '../page/index/content/video'

import FamousColumnsPage from '../page/index/famous/columns'
import FamousStudentPage from '../page/index/famous/student'
import FamousTeachersPage from '../page/index/famous/teachers'
import FamousAlumnusPage from '../page/index/famous/alumnus'

import ProblemColumnsPage from '../page/index/problem/columns'
import ProblemListPage from '../page/index/problem/list'

import ImageSourcePage from '../page/index/source/image'
import AdvertSourcePage from '../page/index/source/advert'
import CarouselSourcePage from '../page/index/source/carousel'
import AppletSourcePage from '../page/index/source/applet'

import FormListPage from '../page/index/form/list'

import ReservePage from '../page/index/apply/reserve'
import ResumingSchoolPage from '../page/index/apply/resuming_school'

const onRedirect = () => {
    const isToken = getToken()
    if (!isToken) {
        throw redirect('/login')
    }

    return true
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout></Layout>,
        loader: onRedirect,
        children: [
            {
                index: true,
                element: <HomePage></HomePage>
            }, {
                path: 'profile',
                loader: () => {
                    const data = { some: "thing" };
                    return new Response(JSON.stringify(data), {
                        status: 200,
                        headers: {
                            "Content-Type": "application/json; utf-8",
                        },
                    });
                },
                element: <SchoolProfilePage></SchoolProfilePage>
            }, {
                path: 'campus',
                element: <CampusPage></CampusPage>
            }, {
                path: 'college',
                element: <CollegePage></CollegePage>
            }, {
                path: 'major',
                element: <MajorPage></MajorPage>
            }, {
                path: 'plan',
                element: <PlanPage></PlanPage>
            }, {
                path: 'score',
                element: <ScorePage></ScorePage>
            }, { 
                path: 'admission',
                element: <AdmissionPage></AdmissionPage>
            },
            
            {
                path: 'column',
                element: <ColumnPage></ColumnPage>
            }, {
                path: 'article',
                element: <ArticlePage></ArticlePage>
            }, {
                path: 'image',
                element: <ImagePage></ImagePage>
            }, {
                path: 'video',
                element: <VideoPage></VideoPage>
            }, 

            {
                path: 'problem_columns',
                element: <ProblemColumnsPage></ProblemColumnsPage>
            }, 
            {
                path: 'problem_list',
                element: <ProblemListPage></ProblemListPage>
            }, 

            {
                path: 'source_image',
                element: <ImageSourcePage></ImageSourcePage>
            }, 
            {
                path: 'source_advert',
                element: <AdvertSourcePage></AdvertSourcePage>
            }, 
            {
                path: 'source_carousel',
                element: <CarouselSourcePage></CarouselSourcePage>
            }, 
            {
                path: 'source_applet',
                element: <AppletSourcePage></AppletSourcePage>
            }, 



            {
                path: 'famous_columns',
                element: <FamousColumnsPage></FamousColumnsPage>
            }, 
            {
                path: 'student',
                element: <FamousStudentPage></FamousStudentPage>
            },
            {
                path: 'teachers',
                element: <FamousTeachersPage></FamousTeachersPage>
            },
            {
                path: 'alumnus',
                element: <FamousAlumnusPage></FamousAlumnusPage>
            },

            {
                path: 'form_list',
                element: <FormListPage></FormListPage>
            },
            

            {
                path: 'reserve',
                element: <ReservePage></ReservePage>
            }, {
                path: 'resuming_school',
                element: <ResumingSchoolPage></ResumingSchoolPage>
            }
        ]    
    },
    {
        path: '/login',
        element: <LoginPage></LoginPage>
    }
])

export default router
