
import { Table } from 'antd';
export const MyTable = ({
    getData,
    columns,
    dataSource,
    total,
    page,
    per_page,
    id = 'id',
    y
}) => {
    const onChange = (pagination) => getData && getData(pagination.current)
    return (
        <Table columns={columns}
            dataSource={dataSource}
            rowKey={id}
            pagination={{
                current: page,
                pageSize: per_page,
                total: total,
                hideOnSinglePage: true,
                showSizeChanger: false
            }}
            scroll={{
                y: `calc(100vh - ${y}px)`
            }}
            onChange={onChange}
        />
    )
}
