import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { onAliyunOssUpload } from '../api/common'
export const AliyunOSSUpload = ({ value, onChange, maxCount }) => {
    const [OSSData, setOSSData] = useState();

    const init = async () => {
        try {
            const {data} = await onAliyunOssUpload();
            setOSSData(data);
        } catch (error) {
            message.error(error);
        }
    };
    useEffect(() => {
        init();
    }, []);
    const handleChange = ({file, fileList }) => {
        console.log('Aliyun OSS:', file, fileList);
    
        onChange?.(file, OSSData.host);
    };
    const onRemove = (file) =>  true;

    const getExtraData = (file) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.accessid,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
    });
    const beforeUpload = async (file) => {

        if (!OSSData) return false;
        const expire = Number(OSSData.expire) * 1000;
        if (expire < Date.now()) {
            await init();
        }
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        // @ts-ignore
        file.url = OSSData.dir + filename;
        return file;
    };
    const uploadProps = {
        name: 'file',
        fileList: value,
        action: OSSData?.host,
        maxCount: maxCount,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        beforeUpload,
        
    };
    return (
        <Upload {...uploadProps}>
            {value.length === 0 ? <Button icon={<UploadOutlined />}>上传</Button> : ''}
        </Upload>
    );
};

