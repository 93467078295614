
import { useEffect, useState } from 'react';
import css from './index.module.css'
import { TreeSelect, Popconfirm, Button, message, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { getCharacter } from '../../../../api/famous'
import { getColumn, onDelete } from '../../../../api/common'
import { AddCampus } from './new_edit';
import { MyTable } from '../../../../components/table'

export const FamousTeachersPage = () => {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
        },

        {
            title: '照片',
            key: 'photo',
            render: (recode) => {
                return recode.photo ? <Image
                    width='100%'
                    height={100}
                    style={{ objectFit: 'cover' }}
                    src={recode.photo}
                /> : <></>
            }
        },
        {
            title: '栏目',
            dataIndex: 'belong_column',
        },
        {
            title: '摘要',
            dataIndex: 'abstract',
        },
        {
            title: '排序',
            dataIndex: 'sort',
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <div style={{
                    display: 'flex',
                    columnGap: '10px',
                    height: '40px',
                    alignItems: 'center',
                    color: '#388bff',
                    cursor: 'pointer'
                }} >
                    <AddCampus value={record} title={'编辑'} getData={getData} options={options}>
                        <span>编辑</span>
                    </AddCampus>


                    <Popconfirm
                        title="删除"
                        description="确认删除该项数据?"
                        onConfirm={() => { confirm(record) }}
                        okText="确定"
                        showCancel={false}
                    >
                        <span>删除</span>
                    </Popconfirm>
                </div>

            ),
        },
    ];
    const [options, setOptions] = useState([])
    const confirm = async (record) => {
        const { errcode } = await onDelete({
            ids: [record.id],
            option: 'character'
        })
        if (errcode === 0) {
            message.success('删除成功');
            getData()
        } else {
            message.error('删除失败');
        }
    };
    const [params, setParams] = useState({
        belong_column_id: null,
        category_key: 5,
        page: 1,
        pre_page: 10
    });

    const handleChange = (value, type) => {
        console.log(value, type);
        setParams({
            ...params,
            [type]: value
        })
    };
    const getOptions = async () => {
        const { data } = await getColumn({
            category_keys: [5],

        })
        setOptions(() => {
            return data.map(item => {
                return {
                    value: item.id,
                    title: item.name,
                    children: item.children.map(v => {
                        return { value: v.id, title: v.name, }
                    })
                }
            })
        })
    }

    const getData = async (obj) => {
        const { data } = await getCharacter(obj || params)
        setList(() => {
            return data.character_list.map((item) => {
                return { ...item, key: item.id }
            })
        })
        setTotal(data.total)
    }

    const onChange = (pagination) => {
        const obj = {
            ...params,
            page: pagination
        }
        setParams(obj)
        getData(obj)
    }

    const resetParams = () => {
        const obj = {
            belong_column_id: null,
            category_key: 5,
            page: 1,
            pre_page: 10
        }
        setParams(obj)
        getData(obj)
    }
    useEffect(() => {
        getOptions()
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>名师风采</h4>
                <AddCampus title='新建' getData={getData} options={options}>
                    <Button type="primary" icon={<PlusOutlined />}>新建</Button>
                </AddCampus>
            </div>
            <div className={css.query}>
                <div className={css.query_input}>
                    <div>栏目：</div>
                    <TreeSelect
                        showSearch
                        style={{
                            width: '268px',
                        }}
                        value={params.belong_column_id}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        placeholder="请选择"
                        allowClear
                        treeDefaultExpandAll
                        onChange={(value) => { handleChange(value, 'belong_column_id') }}
                        treeData={options}
                    />
                </div>

                <Button style={{ margin: '0 12px 0 36px ' }} onClick={resetParams}>重置</Button>
                <Button type="primary" onClick={() => getData()}>查询</Button>
            </div>
            <MyTable columns={columns}
                dataSource={list}
                y={340}
                page={params.page}
                total={total}
                per_page={10}
                getData={onChange}>
            </MyTable>
        </div>

    )
}
export default FamousTeachersPage
