
import css from '../index.module.css'
import { message, Input } from 'antd';
import { useState } from 'react';
import { useValidate } from '../../../../../hook/use-validate'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { editSource } from '../../../../../api/source'
import { MyUpload } from '../../../../../components/upload'

export const AddCampus = ({
    children,
    value,
    title,
    options,
    getData,
}) => {
    const { validate } = useValidate()
    const [params, setParams] = useState({
        id: null,
        image: '',
        description: ''
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onShowModal = async () => {
        setParams(value)
        showModal()
    }
    let validateList = [{
        value: 'image',
        msg: '请上传图片',
        required: true
    }, {
        value: 'description',
        msg: '请输入',
        required: true
    }]
   
       
    const onSubmit = () => {
        validate(validateList, params).then(
            async res => {
                if(res) {
                    const { errcode } = await editSource(params)
                    if (errcode === 0) {
                        message.success('修改成功')
                        getData()
                        resetParams()
                        handleCancel()
                    }
                }
            }
        )
    }

    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }

    const resetParams = () => {
        setParams({
            id: null,
            image: '',
            description: ''
        })
    }
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={800}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={`${css.inputBox} ${css.inputBox1}`}>
                        <div className={css.label}>图片：</div>
                        <MyUpload onChange={onChange}
                            url={params?.image}
                            type='image' />

                    </div>

                    <div className={`${css.inputBox} ${css.inputBox1}`}>
                        <div className={css.label}>描述：</div>
                        <Input.TextArea style={{
                            height: 160,
                            resize: 'none',
                        }}
                            onChange={(e) => { onChange(e.target.value, 'description') }}
                            value={params.description}
                            placeholder="请填写" />
                    </div>
                </div>
            </MyModal>
        </>
    )
}
