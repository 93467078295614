
import { useEffect, useState } from 'react';
import css from './index.module.css'
import { Image } from 'antd';
import { getSource } from '../../../../api/source'
import { AddCampus } from './news_edit';
import { MyTable } from '../../../../components/table'
import icon from '../../../../assets/image/space.png'
export const ImageSourcePage = () => {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: '标题',
            dataIndex: 'description',
        },
        {
            title: '图片',
            key: 'image',
      
            render: (recode) => {
                return recode.image ? <Image
                    width={200}
                    style={{ objectFit: 'cover', borderRadius: '5px' }}
                    src={recode.image}
                /> : <img src={icon} alt="" style={{ width: '200px', borderRadius: '5px' }} />
            }
        },
        {
            title: '操作',
            key: 'action',
            width: 168,
            render: (record) => (
                <div style={{
                    display: 'flex',
                    columnGap: '10px',
                    height: '40px',
                    alignItems: 'center',
                    color: '#388bff',
                    cursor: 'pointer'
                }} >
                    <AddCampus
                        title={'编辑'}
                        value={record}
                        getData={getData}>
                        <span>编辑</span>
                    </AddCampus>
                </div>

            ),
        },
    ];

    const getData = async () => {
        const { data } = await getSource()
        setList(data)
        setTotal(data.total)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>素材列表</h4>
            </div>

            <MyTable columns={columns}
                dataSource={list}
                y={300}
                total={total}
                per_page={4}>
            </MyTable>
        </div>

    )
}
export default ImageSourcePage
