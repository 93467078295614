
import css from '../index.module.css'
import { message, Input, Radio, InputNumber,  } from 'antd';
import { useState } from 'react';
import { useValidate } from '../../../../../hook/use-validate'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { addCarousel, editCarousel } from '../../../../../api/source'
import { MyUpload } from '../../../../../components/upload'

export const AddCampus = ({
    children,
    value,
    title,
    options,
    getData,
}) => {
    const { validate } = useValidate()
    const [params, setParams] = useState({
        title: '',
        image: '',
        mode: 0,
        path: '',
        sort: 99
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onShowModal = async () => {
        if (value?.id) {
            setParams(value)
        }
        showModal()
    }
    let validateList = [{
        value: 'image',
        msg: '请上传图片',
        required: true
    }]
   
       
    const onSubmit = () => {
        validate(validateList, params).then(
            async res => {
                if(res) {
                    if (value?.id) {
                        edit()
                    } else {
                        add()
                    }
                }
            }
        )
    }
    const add = async () => {
        const { errcode } = await addCarousel(params)
        if(errcode === 0) {
            message.success('新增成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const edit = async () => {
        const { errcode } = await editCarousel(params)
        if (errcode === 0) {
            message.success('修改成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    const handleRadio = (e) => {
        const val = e.target.value
        setParams({
            ...params,
            mode: val
        })
    }
    const resetParams = () => {
        setParams({
            title: '',
            image: '',
            mode: 0,
            path: '',
            sort: 99
        })
    }
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={600}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.inputBox}>
                        <div className={css.label}>标题：</div>
                        <Input placeholder="请输入"
                            value={params.title}
                            onChange={(e) => { onChange(e.target.value, 'title') }}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>图片：</div>
                        <MyUpload onChange={onChange} url={params.image} type='image'></MyUpload>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>页面路径：</div>
                        <Input placeholder="请输入"
                            value={params.path}
                            onChange={(e) => { onChange(e.target.value, 'path') }}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>跳转方式：</div>
                        <Radio.Group
                            onChange={handleRadio}
                            value={params.mode}>
                            <Radio value={0}>不跳转</Radio>
                            <Radio value={1}>网页链接</Radio>
                            <Radio value={2}>内部链接</Radio>
                            <Radio value={3}>文档</Radio>
                        </Radio.Group>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>排序：</div>
                        <InputNumber placeholder="请输入"
                            value={params.sort}
                            onChange={(value) => { onChange(value, 'sort') }} />
                    </div>
                </div>
            </MyModal>
        </>
    )
}
