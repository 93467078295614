
import { useState } from "react"
export const useModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return {
        isModalOpen,
        showModal,
        handleCancel
    }
}
