
import { Modal } from 'antd';

export const MyModal = ({
    children,
    isModalOpen,
    handleOk,
    handleCancel,
    title,
    okText = '确定',
    cancelText = '取消',
    centered = false,
    footer = true,
    width,
    isIcon = true,
    isDes = false,
}) => {
    return (
        <Modal title={title}
            destroyOnClose={isDes}
            width={width} 
            closeIcon={isIcon}
            open={isModalOpen} 
            onOk={handleOk}
            onCancel={handleCancel} 
            okText={okText}
            cancelText={cancelText}
            centered={centered}
            footer={(params) => {
                if (footer) {
                    return params
                } 
                return null
            }}>
            {
                children
            }
        </Modal>
    )
}
