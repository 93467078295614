
import { useState } from 'react';
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { Form, Input, message, DatePicker } from 'antd';
import { setupProfile, getProfileConfig } from '../../../../../api/apply'
import dayjs from 'dayjs'
import css from './index.module.css'

export const Settring = ({
    children
}) => {
    const [form] = Form.useForm();
    const formatData = 'YYYY年/MM月'
    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: '必填',
            },
        ],
    };
    
    const inputConfig = {
        rules: [{
            required: true,
            message: '必填',
        }]
    }
    const paramsObj = {
        mail_materials: "",
        recipient_address: "",
        recipient_name: "",
        recipient_phone: "",
        admitted: null,
        retention_duration: "",
        sign: null,
        validity_start: null,
        validity_end: null,
    }
    const [params, setParams] = useState(paramsObj)
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()
   
    const onSubmit = () => {
        form.validateFields().then(async values => {
            const obj = {
                ...values,
                admitted_year: values.admitted.format('YYYY'),
                admitted_month: values.admitted.format('MM'),
                validity_start_year: values.validity_start.format('YYYY'),
                validity_start_month: values.validity_start.format('MM'),
                validity_end_year: values.validity_end.format('YYYY'),
                validity_end_month: values.validity_end.format('MM'),
                sign_year: values.sign.format('YYYY'),
                sign_month: values.sign.format('MM'),
            }

            const { errcode } = await setupProfile(obj)
            if (errcode === 0) {
                message.success('保存成功')
                resetParams()
                handleCancel()
            }
        }).catch((error) => {

        }) 
    }
    const getDetail = async () => {
        const { data } = await getProfileConfig()
        const obj = {
            mail_materials: data.mail_materials,
            recipient_address: data.recipient_address,
            recipient_name: data.recipient_name,
            recipient_phone: data.recipient_phone,
            retention_duration: data.retention_duration,
            admitted: data.admitted_year && dayjs(data.admitted_year + '/' + data.admitted_month, formatData),
            sign: data.sign_year && dayjs(data.sign_year + '/' + data.sign_month, formatData),
            validity_start: data.validity_start_year && dayjs(data.validity_start_year + '/' + data.validity_start_month, formatData),
            validity_end: data.validity_end_year && dayjs(data.validity_end_year + '/' + data.validity_end_month, formatData),
        }

        setParams(obj)
        showModal()
    }
    const resetParams = () => setParams(paramsObj)
    return (
        <>
            <div onClick={getDetail}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title='设置'
                isDes={true}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.content}>
                    <Form form={form}
                        layout='vertical'
                        initialValues={params}
                        name='setup'
                        style={{ width: '100%' }}>
                        <Form.Item
                            {...inputConfig}
                            name="recipient_name"
                            label="收件人名称"

                        >
                            <Input
                                placeholder="请输入"
                            />
                        </Form.Item>

                        <Form.Item
                            {...inputConfig}
                            name="recipient_phone"
                            label="收件人联系电话"

                        >
                            <Input
                                placeholder="请输入"
                            />
                        </Form.Item>

                        <Form.Item
                            {...inputConfig}
                            name="recipient_address"
                            label="收件人地址"
                        >
                            <Input
                                placeholder="请输入"
                            />
                        </Form.Item>
                        <Form.Item
                            {...config}
                            picker="month"
                            label="录取时间"
                            name="admitted">
                            <DatePicker style={{ width: '100%' }}
                                format={formatData}
                                picker="month"
                                placeholder='请选择年/月' />
                        </Form.Item>

                        <Form.Item>
                            <Form.Item
                                {...config}
                                picker="month"
                                style={{
                                    display: 'inline-block',
                                    width: 'calc(50% - 8px)',
                                    margin: 0
                                }}
                                label="开始时间"
                                name="validity_start">
                                <DatePicker style={{ width: '100%' }}
                                    format={formatData}
                                    picker="month"
                                    placeholder='请选择年/月' />
                            </Form.Item>
                            <Form.Item
                                {...config}
                                style={{
                                    display: 'inline-block',
                                    width: 'calc(50% - 8px)',
                                    margin: '0 0 0 16px'
                                }}
                                label="结束时间"
                                name="validity_end">
                                <DatePicker style={{ width: '100%' }}
                                    format={formatData}
                                    picker="month"
                                    placeholder='请选择年/月' />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item
                            {...config}
                            picker="month"
                            label="签名时间"
                            name="sign">
                            <DatePicker style={{ width: '100%' }}
                                format={formatData}
                                picker="month"
                                placeholder='请选择年/月' />
                        </Form.Item>

                        <Form.Item
                            {...inputConfig}
                            name="retention_duration"
                            label="有效期"
                        >
                            <Input
                                placeholder="请输入"
                            />
                        </Form.Item>

                        <Form.Item
                            {...inputConfig}
                            name="mail_materials"
                            label="邮件说明"
                        >
                            <Input.TextArea style={{
                                height: 160,
                                resize: 'none',
                            }}
                                placeholder="请填写" />
                        </Form.Item>
                    </Form>
                </div>
            </MyModal>
        </>
    )
}
