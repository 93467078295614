
import { useEffect, useState } from 'react';
import css from './index.module.css'
import { Popconfirm, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { getMajors } from '../../../../api/department'
import { onDelete } from '../../../../api/common'
import { AddCampus } from './new_edit';
import { MySelect } from './my-select';
import { MyTable } from '../../../../components/table';
export const MajorPage = () => {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const initParams = {
        belong_college_id: '',
        per_page: 10,
        page: 1
    }
    const [params, setParams] = useState(initParams)
    const columns = [
        {
            title: '专业名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '所属学院',
            dataIndex: 'belong_college',
            key: 'belong_college',
        },
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
        },

        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <div style={{
                    display: 'flex',
                    columnGap: '10px',
                    height: '40px',
                    alignItems: 'center',
                    color: '#388bff',
                    cursor: 'pointer'
                }} >
                    <AddCampus value={record.id} title={'编辑'} getData={getData}>
                        <span>编辑</span> 
                    </AddCampus>
                   
                   
                    <Popconfirm
                        title="删除"
                        description="确认删除该项数据?"
                        onConfirm={() => { confirm(record) }}
                        okText="确定"
                        showCancel={false}
                    >
                        <span>删除</span>
                    </Popconfirm>
                </div>
                
            ),
        },
    ];
    const confirm = async (record) => {
        const { errcode } = await onDelete({
            ids: [record.id],
            option: 'major'
        })
        if (errcode === 0) {
            message.success('删除成功');
            getData()
        } else {
            message.error('删除失败');
        }
    };
    const handleChange = (value) => {
        setParams({
            per_page: 10,
            page: 1,
            belong_college_id: value
        })
    };
    const getData = async (obj) => {
        const { data } = await getMajors(obj || params)
        setList(data.major_list)
        setTotal(data.total)
    }
    const onChange = (pagination) => {
        const obj = {
            ...params,
            page: pagination
        }
        setParams(obj)
        getData(obj)
    }

    const resetParams = () => {
        setParams(initParams)
        getData(initParams)
    }
    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>专业列表</h4>
                <AddCampus title='新建' getData={getData}>
                    <Button type="primary" icon={<PlusOutlined />}>新建</Button>
                </AddCampus>
            </div>
            <div className={css.query}>
                <span>所属学院：</span>
                <MySelect
                    value={params.belong_college_id} 
                    onChange={handleChange}/>
                
                <Button style={{ margin: '0 12px 0 36px ' }} onClick={resetParams}>重置</Button>
                <Button type="primary" onClick={() => getData()}>查询</Button>
            </div>
            <MyTable columns={columns}
                dataSource={list}
                total={total}
                y={340}
                getData={onChange}>
            </MyTable>
        </div>
         
    )
}
export default MajorPage
