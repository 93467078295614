
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { 
    QuestionOutlined, 
    ApartmentOutlined, 
    AuditOutlined,
    AlignLeftOutlined,
    TeamOutlined,
    FileTextOutlined,
    FormOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import {setNavKey, getNavKey} from '../../../../store/storage'
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
const items = [
    getItem('院系专业', 'dep', <ApartmentOutlined />, [
        getItem('学校简介', 'profile'),
        getItem('校区位置', 'campus'),
        getItem('院系管理', 'college'),
        getItem('专业列表', 'major'),
    ]),
    getItem('招生管理', 'rec', <AuditOutlined />, [
        getItem('招生计划', 'plan'),
        getItem('历年分数', 'score'),
        getItem('录取查询', 'admission'),
    ]),
    getItem('内容管理', 'content', <AlignLeftOutlined />, [
        getItem('栏目管理', 'column'),
        getItem('文章列表', 'article'),
        getItem('图片列表', 'image'),
        getItem('视频列表', 'video'),
    ]),
    getItem('人物风采', 'famous', <TeamOutlined />, [
        getItem('栏目管理', 'famous_columns'),
        getItem('优秀学子', 'student'),
        getItem('名师风采', 'teachers'),
        getItem('知名校友', 'alumnus'),
    ]),
    getItem('常见问题', 'problem', <QuestionOutlined />, [
        getItem('栏目管理', 'problem_columns'),
        getItem('问题列表', 'problem_list'),
    ]),
    getItem('素材管理', 'source', <FileTextOutlined />, [
        getItem('图片管理', 'source_image'),
        getItem('广告管理', 'source_advert'),
        getItem('轮播图管理', 'source_carousel'),
        getItem('小程序路径', 'source_applet'),
    ]),
    getItem('表单管理', 'form', <FormOutlined />, [
        getItem('表单列表', 'form_list'),
    ]),
    getItem('入学资格保留', 'apply', <SolutionOutlined />,[
        getItem('保留资格申请', 'reserve'),
        getItem('复学资格申请', 'resuming_school'), 
    ]),
];

const rootSubmenuKeys = ['dep', 'rec', 'content', 'famous', 'problem', 'source', 'form', 'apply'];

export const SidebarModule = () => {

    const navigate = useNavigate()

    const [openKeys, setOpenKeys] = useState(['']);
    const [selectedKeys, setSelectedKeys] = useState(['']);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const onNavagate = (data) => {
        setNavKey(data.keyPath)
        navigate(data.key)
        setSelectedKeys(data.key)
    };
    const init = () => {
        const initData = getNavKey()
        if (initData) {
            console.log(JSON.parse(initData)[0]);
            setOpenKeys([JSON.parse(initData)[1]])
            setSelectedKeys([JSON.parse(initData)[0]])
        }
    }
    useEffect(() => {
        init()
    }, [])
    return (
        <Menu
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenChange={onOpenChange}
            onClick={onNavagate}
            style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '4px',
            }}
            items={items}
        />
    );
};

