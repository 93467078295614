// 素材管理
import request from '../utils/http'

// 图片素材
export const getSource = (data) => {
    return request({
        url: '/b/material/get',
        method: 'post',
        data
    })
}

export const editSource = (data) => {
    return request({
        url: '/b/material/edit',
        method: 'post',
        data
    })
}

// 广告素材
export const getAdvert = () => {
    return request({
        url: '/b/advert/get',
        method: 'post',
    })
}
export const editAdvert = (data) => {
    return request({
        url: '/b/advert/edit',
        method: 'post',
        data
    })
}


// 轮播图
export const getCarousel = () => {
    return request({
        url: '/b/carousel/get',
        method: 'post',
    })
}
export const addCarousel = (data) => {
    return request({
        url: '/b/carousel/add',
        method: 'post',
        data
    })
}
export const editCarousel = (data) => {
    return request({
        url: '/b/carousel/edit',
        method: 'post',
        data
    })
}

// 轮播图
export const getMiniappPath = () => {
    return request({
        url: '/b/miniapp_path/get',
        method: 'post',
    })
}

