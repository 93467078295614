
import request from '../utils/http'
// 用户
export const getUserInfo = () => {
    return request({
        url: '/b/account/get',
        method: 'post',
    })
}

export const editPwd = (data) => {
     return request({
         url: '/b/password/edit',
         method: 'post',
         data,
     })
}
