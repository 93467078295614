
import { useEffect, useState, useRef } from 'react';
import css from './index.module.css'
import { Popconfirm, message, Button, DatePicker, Select, Image } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'
import { getProfile, exopProfile } from '../../../../api/apply'
import { onDelete } from '../../../../api/common'
import { Process } from '../component/process';
import { MyTable } from '../../../../components/table';
import { Settring } from '../component/setting';
const { RangePicker } = DatePicker;
export const ReservePage = () => {
    const [total, setTotal] = useState(0)
    const oneEff = useRef(true)
    const imageRef = useRef('')
    const [list, setList] = useState([])
    const [keyValue, setKeyValue] = useState('')
    const [visible, setVisible] = useState(false);
    const [params, setParams] = useState({
        application_type: 1,
        audit_status: '',
        start_time: null,
        end_time: null,
        page: 1,
        per_page: 10,
    })
    const options = [
        {
            value: 1,
            label: '待审',
        },
        {
            value: 2,
            label: '通过',
        },
        {
            value: 3,
            label: '未通过',
        },
        {
            value: 4,
            label: '复审',
        },
    ]
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '性别',
            dataIndex: 'gender',
        },
        {
            title: '身份证号',
            dataIndex: 'id_number',
        },
     
        {
            title: '联系电话',
            dataIndex: 'phone',
        },
        {
            title: '学院',
             dataIndex: 'college',
        }, {
            title: '专业',
            dataIndex: 'major',
        },
        {
            title: '原因',
            dataIndex: 'application_reason',
        },
        {
            title: '审核状态',
            dataIndex: 'audit_status',
        },
        {
            title: '操作',
            width: 148,
            key: 'action',
            render: (record) => (
                <div style={{
                    display: 'flex',
                    columnGap: '10px',
                    height: '40px',
                    alignItems: 'center',
                    color: '#388bff',
                    cursor: 'pointer'
                }} >
                    <Process params={{
                        application_type: 1,
                        application_id: record.application_id
                    }} options={options} title='新生保留入学资格审核' getData={getData}>
                        审核
                    </Process>
                    
                    <Popconfirm
                        title="删除"
                        description="确认删除该项数据?"
                        onConfirm={() => { confirm(record) }}
                        okText="确定"
                        showCancel={false}
                    >
                        <span>删除</span>
                    </Popconfirm>
                    {
                      
                        record.qualification_proof_photo && 
                        <span onClick={() => { 
                                imageRef.current = record.qualification_proof_photo
                                setVisible(true)
                            }}>
                            预览
                        </span>
                    }
                    
                </div>
            ),
        },

    ]
    const getData = async (val) => {
        const { data } = await getProfile(val || params)
        setList(data.freshmen_list)
        setTotal(data.total)
    }
    const confirm = async (record) => {
        const { errcode } = await onDelete({
            ids: [record.application_id],
            option: 'freshmen'
        })
        if (errcode === 0) {
            message.success('删除成功');
            getData()
        } else {
            message.error('删除失败');
        }
    };
    const exportList = async () => {
        const {data, errcode} = await exopProfile({
            application_type: 1,
        })
        if (errcode === 0) {
            let link = document.createElement('a');
            link.href = data;
            link.download = '新生保留入学资格名单.xlsx';
            link.click();
        } else {
            message.error('导出名单失败')
        }
     
    }
    const handleChange = (value) => {
        setParams((prevData) => {
            return {
                ...prevData,
                audit_status: value
            }
        })
    }
    const resetParams = () => {
        const data = {
            application_type: 1,
            audit_status: '',
            start_time: null,
            end_time: null,
            page: 1,
            per_page: 10,
        }
        setParams(data)

        getData(data)
        
        setKeyValue(+new Date())
    }
   
    const onChangeDate = (dates, dateStrings) => {
        setParams((prevData) => {
            return {
                ...prevData,
                start_time: dateStrings[0],
                end_time: dateStrings[1]
            }
        })
    }

    useEffect(() => {
        if (oneEff.current) {
            getData()
            oneEff.current = false
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
       <>
            <div className={css.container}>
                <div>
                    <div className={css.header}>
                        <h4 className={css.title}>新生保留入学资格申请列表</h4>
                        <div className={css.headRight}>
                            <Button icon={<DownloadOutlined />} onClick={exportList}>导出</Button>
                            <Settring>
                                <Button type='primary'>系统设置</Button>
                            </Settring>
                        </div>
                    </div>
                    <div className={css.query}>
                        <div>
                            <span>审核状态：</span>
                            <Select
                                placeholder='请选择'
                                style={{
                                    width: 268,
                                }}
                                key={keyValue}
                                onChange={(value) => { handleChange(value) }}
                                options={options}
                            />
                        </div>
                        <div>
                            <span>时间：</span>
                            <RangePicker key={keyValue} onChange={onChangeDate} />
                        </div>
                        <div>
                            <Button style={{ margin: '0 12px 0 24px ' }} onClick={resetParams}>重置</Button>
                            <Button type="primary" onClick={() => getData()}>查询</Button>
                        </div>
                    </div>
                </div>
                <MyTable columns={columns}
                    dataSource={list}
                    total={total}
                    per_page={20}
                    y={340}
                    id={'application_id'}
                    getData={getData}>
                </MyTable>
            </div>

            <Image
                width={200}
                style={{
                    display: 'none',
                }}
               
                preview={{
                    visible,

                    src: imageRef.current,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    },
                }}
            />   
        </>

    )
}
export default ReservePage
