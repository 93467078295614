
import { Select } from 'antd';
import { useState, useEffect } from 'react';
import { getCollege } from '../../../../api/department'

export const MySelect = ({ onChange, value }) => {
    const [options, setOptions] = useState([])
    const getData = async () => {
        const { data } = await getCollege()
        setOptions(() => {
            return data.map(item => {
                return { value: item.id, label: item.name }
            })
        })
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Select
            key={value}
            defaultValue={value || '请选择'}
            style={{
                width: 268,
            }}
            onChange={(value) => { onChange(value)}}
            options={options}
        />
    )
}
