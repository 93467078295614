
import { useEffect, useState } from 'react';
import css from './index.module.css'
import { getMiniappPath } from '../../../../api/source'
import { MyTable } from '../../../../components/table'

export const AppletSourcePage = () => {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: '路径名称',
            dataIndex: 'name',
        },
        {
            title: '路径URL',
            dataIndex: 'path',
        },
    ];

    const getData = async () => {
        const { data } = await getMiniappPath()
        setList(() => {
            return data.map((item, index) => {
                return { ...item, id: index }
            })
        })
        setTotal(data.total)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>小程序路径</h4>
            </div>

            <MyTable columns={columns}
                dataSource={list}
                total={total}
                y={280}
                per_page={20}>
            </MyTable>
        </div>

    )
}
export default AppletSourcePage

