
import { Form, Button } from 'antd';

export const MyForm = ({
    form,
    children,
    onFinish,
    style,
    name,
    okText,
    btnStyle,
    layout,
    size = 'middle'
}) => {
    return (
        <Form
            form={form}
            name={name}
            size={size}
            style={style}
            onFinish={onFinish}
            layout={layout}
        >
            {
                children
            }
            {
                okText ? <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" style={btnStyle}>
                        {okText}
                    </Button>
                </Form.Item> : ''
            }
        </Form>
    )
}
