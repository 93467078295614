
import css from './index.module.css'
export const HomePage = () => {

    return (
        <div className={css.main}></div>
    )
}

export default HomePage
