// 内容管理
import request from '../utils/http'

// 文章列表
export const getArticle = (data) => {
    return request({
        url: '/b/content/article/get_list',
        method: 'post',
        data
    })
}

export const addArticle = (data) => {
    return request({
        url: '/b/content/article/add',
        method: 'post',
        data
    })
}

export const editArticle = (data) => {
    return request({
        url: '/b/content/article/edit',
        method: 'post',
        data
    })
}

export const getArticleDetail = (data) => {
    return request({
        url: '/b/content/article/get_detail',
        method: 'post',
        data
    })
}

// 图片列表
export const getPicture = (data) => {
    return request({
        url: '/b/content/picture/get',
        method: 'post',
        data
    })
}
export const addPicture = (data) => {
    return request({
        url: '/b/content/picture/add',
        method: 'post',
        data
    })
}
export const editPicture = (data) => {
    return request({
        url: '/b/content/picture/edit',
        method: 'post',
        data
    })
}


// 视频列表
export const getVideo = (data) => {
    return request({
        url: '/b/content/video/get',
        method: 'post',
        data
    })
}
export const addVideo = (data) => {
    return request({
        url: '/b/content/video/add',
        method: 'post',
        data
    })
}
export const editVideo = (data) => {
    return request({
        url: '/b/content/video/edit',
        method: 'post',
        data
    })
}
