
import css from '../index.module.css'
import { message, Input, Radio, TreeSelect, Switch, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useState, useRef } from 'react';
import { useValidate } from '../../../../../hook/use-validate'
import { QuillModule } from '../../../../../components/editor'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { addArticle, editArticle, getArticleDetail } from '../../../../../api/content'
import { MyUpload } from '../../../../../components/upload'
import { AliyunOSSUpload } from '../../../../../components/aliyun_oss'
const { TextArea } = Input;

export const AddCampus = ({
    children,
    value,
    title,
    options,
    getData,
}) => {
    const childRef = useRef(null)
    const { validate } = useValidate()
    const [file, setFile] = useState([])
    const [params, setParams] = useState({
        title: '',
        abstract: '',
        belong_column_id: null,
        is_top: 0,
        web_url: '',
        doc_url: '',
        mode: 1,
        thumbnail: '',
        text: '',
        release_time: ''
    })
    const validateList = useRef([{
        value: 'title',
        msg: '请输入标题',
        required: true
    }, {
        value: 'belong_column_id',
        msg: '请选择类别',
        required: true
    }, {
        value: 'text',
        msg: '请输入文章内容',
        required: true
    }, {
        value: 'web_url',
        msg: '请输入链接地址',
        required: false
    }, {
        value: 'doc_url',
        msg: '请先上传文档',
        required: false
    }])

    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    const changeEditorHtml = (val) => {
        setParams(pre => {
            return {
                ...pre,
                text: val
            }
        })
    }
    const onShowModal = async () => {
        if (value) {
            const { data } = await getArticleDetail({id: value})
            setParams(data)

            if (data.mode === 3) {
                setFile([{
                    name: data.doc_url
                }])
            }
          
            handleRquire(data.mode)
        }
        showModal()
    }

       
    const onSubmit = () => {
        validate(validateList.current, params)
        .then(res => {
            console.log(res);
            if(res) {
                if (value) {
                    edit()
                } else {
                    add()
                }
            }
        })
    }
    const edit = async () => {
        const { errcode } = await editArticle(params)
        if (errcode === 0) {
            message.success('修改成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const add = async () => {
        const { errcode } = await addArticle(params)
        if (errcode === 0) {
            message.success('新建成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const handleRadio = (e) => {
        const val = e.target.value
        setParams({
            ...params,
            mode: val
        })
        handleRquire(val)
    }
    const handleRquire = (val) => {
        validateList.current = validateList.current.map((item) => {

            if (item.value !== 'title' && item.value !== 'belong_column_id') {
                item.required = false
            }
            if (val === 1 && item.value === 'text') {
                item.required = true
            }
            if (val === 2 && item.value === 'web_url') {
                item.required = true
            }
            if (val === 3 && item.value === 'doc_url') {
                item.required = true
            }

            return item
        })
    }
    const handleChange = (val) => {
        setParams({
            ...params,
            belong_column_id: val
        })
    }
    const handleIsTop = (checked) => {
        setParams({
            ...params,
            is_top: checked ? 1 : 0
        })
    }
    const resetParams = () => {
        setParams({
            title: '',
            belong_column_id: null,
            is_top: 0,
            web_url: '',
            doc_url: '',
            mode: 1,
            thumbnail: '',
            text: '',
            abstract: ''
        })
        setFile([])
    }
    const uploadOssFile = (file, host) => {
        console.log(file, host);
        if (file.status === "removed") {
            setParams({
                ...params,
                doc_url: ''
            })
            return setFile([])
        }

        if (file.status === 'done') {
            message.success('文件上传成功')
            setParams({
                ...params,
                doc_url: host + '/' + file.url
            })
        }
        setFile([file])
        
    }

    const onChangeDate = (value, dateString) => {
        setParams({
            ...params,
            release_time: dateString
        })
    };
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={800}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.inputBox}>
                        <div className={css.label}>标题：</div>
                        <Input placeholder="请输入"
                        
                            value={params.title}
                            onChange={(e) => { onChange(e.target.value, 'title') }}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>类目：</div>
                        <TreeSelect
                           
                            style={{
                                width: '268px',
                            }}
                            value={params.belong_column_id}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="请选择"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(value) => { handleChange(value) }}
                            treeData={options}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>缩略图：</div>
                        <MyUpload onChange={onChange} url={params.thumbnail} type='thumbnail'></MyUpload>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>置顶：</div>
                        <Switch defaultChecked={Boolean(params.is_top)} onChange={handleIsTop} />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>类别：</div>
                        <Radio.Group
                            onChange={handleRadio}
                            value={params.mode}>
                            <Radio value={1}>富文本</Radio>
                            <Radio value={2}>网页链接</Radio>
                            <Radio value={3}>文档</Radio>
                        </Radio.Group>
                    </div>
                   
                    {
                        value ? <div className={css.inputBox}>
                            <div className={css.label}>发布日期：</div>
                            <DatePicker
                                showTime
                                defaultValue={dayjs(params.release_time, 'YYYY-MM-DD hh:mm:ss')}
                                onChange={onChangeDate} />
                        </div> : ''
                    }
                    <div className={css.inputBox}>
                        <div className={css.label}>摘要：</div>
                        <TextArea placeholder="请输入"

                            value={params.abstract}
                            onChange={(e) => { onChange(e.target.value, 'abstract') }}
                        />
                    </div>
                </div>
                <div className={css.quillBox}>
                    {
                        params.mode === 1 && <QuillModule
                            ref={childRef}
                            value={params.text}
                            changeEditorHtml={changeEditorHtml}
                        ></QuillModule>
                    }
                    {
                        params.mode === 2 && <div className={css.inputBox}>
                            <div className={css.label}>网页链接：</div>
                            <Input placeholder="请输入" value={params.web_url} onChange={(e) => { onChange(e.target.value, 'web_url') }} />
                        </div>
                    }
                    {
                        params.mode === 3 && <div className={css.inputBox}>
                            <div className={css.label}>文件：</div>
                            <AliyunOSSUpload value={file} onChange={uploadOssFile} maxCount={1} />
                        </div>
                        
                    }

                </div> 

                
               
            </MyModal>
        </>
    )
}
