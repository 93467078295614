
import css from '../index.module.css'
import { message, Input, TreeSelect, InputNumber } from 'antd';
import { useState } from 'react';
import { useValidate } from '../../../../../hook/use-validate'
import { QuillModule } from '../../../../../components/editor'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { addCharacter, editCharacter } from '../../../../../api/famous'
import { MyUpload } from '../../../../../components/upload'


export const AddCampus = ({
    children,
    value,
    title,
    options,
    getData,
}) => {
    const { validate } = useValidate()

    const [params, setParams] = useState({
        name: '',
        belong_column_id: null,
        introduction: '',
        photo: '',
        sort: 99,
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    const changeEditorHtml = (val) => {
        setParams(pre => {
            return {
                ...pre,
                introduction: val
            }
        })
    }
    const onShowModal = async () => {
        if (value) {
            setParams(value)
        }
        showModal()
    }
    let validateList = [{
        value: 'name',
        msg: '请输入标题',
        required: true
    }, {
        value: 'belong_column_id',
        msg: '请选择类别',
        required: true
    }, {
        value: 'photo',
        msg: '请上传图片',
        required: true
    }]
   
       
    const onSubmit = () => {
        validate(validateList, params)
        .then(res => {
            if(res) {
                if (value) {
                    edit()
                } else {
                    add()
                }
            }
        })
    }
    const edit = async () => {
        const { errcode } = await editCharacter(params)
        if (errcode === 0) {
            message.success('修改成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const add = async () => {
        const { errcode } = await addCharacter(params)
        if (errcode === 0) {
            message.success('新建成功')
            getData()
            resetParams()
            handleCancel()
        }
    }

    const handleChange = (val) => {
        setParams({
            ...params,
            belong_column_id: val
        })
    }

    const resetParams = () => {
        setParams({
            name: '',
            belong_column_id: null,
            introduction: '',
            photo: '',
            sort: 99,
        })
    }
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={800}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.inputBox}>
                        <div className={css.label}>姓名：</div>
                        <Input placeholder="请输入"
                            value={params.name}
                            onChange={(e) => { onChange(e.target.value, 'name') }}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>类目：</div>
                        <TreeSelect
                            style={{
                                width: '268px',
                            }}
                            value={params.belong_column_id}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="请选择"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(value) => { handleChange(value) }}
                            treeData={options}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>照片：</div>
                        <MyUpload onChange={onChange} url={params.photo} type='photo'></MyUpload>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.inputBox}>
                            <div className={css.label}>排序：</div>
                            <InputNumber placeholder="请输入" value={params.sort} onChange={(value) => { onChange(value, 'sort') }} />
                        </div>
                    </div>
                </div>
                <div className={css.quillBox}>
                  
                    <QuillModule
                        value={params.introduction}
                        changeEditorHtml={changeEditorHtml}
                    ></QuillModule>
                  
                </div> 

                
               
            </MyModal>
        </>
    )
}
