
// 入学资格申请保留
import request from '../utils/http'

export const getProfile = (data) => {
    return request({
        url: '/b/freshmen/application/list/get',
        method: 'post',
        data
    })
}
export const exopProfile = (data) => {
    return request({
        url: '/b/freshmen/application/export',
        method: 'post',
        data
    })
}
export const detProfile = (data) => {
    return request({
        url: '/b/freshmen/application/detail/get',
        method: 'post',
        data
    })
}
export const auditProfile = (data) => {
    return request({
        url: '/b/freshmen/application/audit',
        method: 'post',
        data
    })
}
export const getProfileConfig = () => {
    return request({
        url: '/b/freshmen/config/get',
        method: 'post',
    })
}
export const setupProfile = (data) => {
    return request({
        url: '/b/freshmen/config/setup',
        method: 'post',
        data
    })
}
