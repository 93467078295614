
import css from './index.module.css'
import login from '../../assets/image/logo.png'
import { Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { loginApi } from '../../api/login'
import { setToken } from '../../store/storage';
import { useNavigate } from 'react-router-dom';
import { MyForm } from '../../components/form';

const FormModule = () => {
    const navigate = useNavigate()

    const onFinish = async (values) => {
        try {
            const { data } = await loginApi(values)

            setToken(data)
            navigate('/')
        } catch (error) {
            console.log(error);
        }
       
       
    
    };
    return (
        <MyForm name={'login'} 
            okText={'登录'} 
            onFinish={onFinish} 
            style={{width: '100%'}}
            btnStyle={{
                width: '100%'
            }}
           >
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: '请输入用户名称',
                    },
                ]}
            >
                <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: '请输入密码',
                    },
                ]}
            >
                <Input
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密码"
                />
            </Form.Item>
           
        </MyForm>
    )
}

export const LoginPage = () => {
    return (
        <div className={css.container}>
            <div className={css.main}>
                <div className={css.head}>
                    <div className={css.titleBox}>
                        <div className={css.loginImg}>
                            <img src={login} alt="" />
                        </div>
                        <h4 className={css.title}>招生管理系统</h4>
                    </div>
                    <div className={css.subTitle}>邦妮招生，让招生更数字化</div>
                </div>

                <FormModule></FormModule>
            </div>
        </div>
    )
}

export default LoginPage
