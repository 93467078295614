
import css from '../index.module.css'
import { message, Button } from 'antd';
import { useState, useEffect } from 'react';
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { addForm, editForm, getFormDetail, getTextCheck } from '../../../../../api/form'
import { FormInfo  } from './info'
import { FormQuestion} from './question'
export const AddCampus = ({
    children,
    value,
    title,
    getData,
}) => {
    const [visible, setVisible] = useState(true)

    const [params, setParams] = useState({
        title: "",
        description: "",
        submit_alias: "",
        end_prompt: "",
        sort: 1,
        start_time: "",
        end_time: ""
    })
    const option = {
        option_value: "",
        option_sort: 1
    }
    const questionItem = {
        question_title: "",
        question_description: "",
        question_type: 1,
        question_is_required: 0,
        question_text_check: 0,
        question_sort: 1,
        question_options: [option]
    }
    const [questions, setQuestions] = useState([questionItem])
    const [textCheck, setTextCheck] = useState([])
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()
    const onShowModal = async () => {
        if (value?.id) {
            const { data } = await getFormDetail({ form_id: value?.id })
            console.log(data);
            setQuestions(data.questions)
            setParams({
                title: data.title,
                description: data.description,
                submit_alias: data.submit_alias,
                end_prompt: data.end_prompt,
                sort: data.sort,
                start_time: data.start_time,
                end_time: data.end_time,
            })
        }
        showModal()
    }

    const onSubmit = () => {
        const flag = questions.every((item) => {
            return item.question_title !== ''
        })
        if (!flag) {
            return message.error('问题不能为空')
        } 
        const obj = {...params, questions}
        if (value?.id) {
            edit({ form_id: value?.id, ...obj })
        } else {
            add(obj)
        }
    }
    const edit = async (params) => {
        const { errcode } = await editForm(params)
        if (errcode === 0) {
            message.success('修改成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const add = async (params) => {
        console.log(params);
        const { errcode } = await addForm(params)
        if (errcode === 0) {
            message.success('新建成功')
            getData()
            resetParams()
            handleCancel()
        }
    }

    const resetParams = () => {
  
    }



    const onChangeInfo = (val, type) => {
        setParams({
            ...params,
            [type]: val
        })
    }
    const onChangeDate = (value, dateString) => {
        setParams({
            ...params,
            start_time: dateString[0],
            end_time: dateString[1]
        })
    };

    const onChangeQuestion = (val, type, index) => {
        setQuestions(prevData => {
            const arr =  prevData.map((item, idx) => {
                if(idx === index) {
                    return {...item, [type]: val}
                }
                return item
            })
            return arr

        })
    }
    const onAddQuestion = () => {
        setQuestions((prevData) => {
             return [
                 ...prevData,
                 questionItem
             ]
        })
    }
    const onCopyQuestion = (i) => {
        setQuestions((prevData) => {
            const obj = prevData.find((item, index) => index === i) 
            return [...prevData, obj]
        })
    }
    const onDeleteQuestion = (index) => {

        if (questions.length <= 1) return message.error('至少保留一个问题')
        setQuestions((prevData) => {
            return prevData.filter((v, i) => {
                return index !== i
            }) 
        })
    }

    const onHandleOption = (val, type, index, i) => {
        setQuestions(prevData => {
            const arr = prevData.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        question_options: item.question_options.map((option_v, option_i) => {
                            if (option_i === i) {
                                return {...option_v, [type]: val}
                            }
                            return option_v
                        })
                    }
                }
                return item
            })
            return arr
        })
    }
    const onAddOption = (index) => {
        setQuestions((prevData) => {
            return prevData.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        question_options: [...item.question_options, option]
                    }
                }
                return item
            })
        })
    }
    const onDeleteOption = (index, i) => {
        setQuestions(prevData => {
            return prevData.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        question_options: item.question_options.filter((option_v, option_i) => option_i !== i)
                    }
                }
                return item
            })
        })
    }
    const onCopyOption = (index, i) => {
        setQuestions((prevData) => {
            return prevData.map((item, idx) => {
                if (idx === index) {
                    const obj = item.question_options.find((option_v, index) => index === i)
                    return {
                        ...item,
                        question_options: [...item.question_options, obj]
                    }
                }
                return item
            })
        })
    }
    
    const getText = async () => {
        const { data } = await getTextCheck()

        setTextCheck(() => {
            return data.map(item => {
                return { value: item.k, label: item.v }
            })
        })
    }
    useEffect(() => {
        getText()
    }, [])
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={600}
                footer={false}
                centered={true}
                handleCancel={() => {
                    setParams({})
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.title}>{visible ? '1、表单信息' : '2、表单列表项'}</div>
                    {
                        visible ? 
                            <FormInfo 
                                params={params}
                                onChange={onChangeInfo}
                                onChangeDate={onChangeDate}
                            /> :
                            <FormQuestion questions={questions}
                                textCheck={textCheck}
                                onChange={onChangeQuestion}
                                onAddQuestion={onAddQuestion}
                                onDeleteQuestion={onDeleteQuestion}
                                onHandleOption={onHandleOption}
                                onCopyQuestion={onCopyQuestion}
                                onDeleteOption={onDeleteOption}
                                onAddOption={onAddOption}
                                onCopyOption={onCopyOption} />
                    }
                </div>
                
                <div className={css.btnBox}>
                    {
                        visible && <Button type="primary" onClick={() => {
                            if (!params.title) return message.error('请输入标题')
                            setVisible(false)
                            
                        }}>下一步</Button>
                    }
                    {
                        !visible && <>
                            <Button  onClick={() => setVisible(true)} style={{marginRight: '10px'}}>上一步</Button>
                            <Button type="primary" onClick={onSubmit}>提交</Button>
                        </>
                    }
                </div>
            </MyModal>
        </>
    )
}
