import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ConfigProvider } from "antd";
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn'
import 'quill/dist/quill.snow.css'
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import router from './router'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <RouterProvider router={router}></RouterProvider>
    </ConfigProvider>
  </React.StrictMode>
  
);

reportWebVitals();
