// 表单管理
import request from '../utils/http'

export const getFormList = (data) => {
    return request({
        url: '/b/form/get_list',
        method: 'post',
        data
    })
}
export const addForm = (data) => {
    return request({
        url: '/b/form/add',
        method: 'post',
        data
    })
}
export const editForm = (data) => {
    return request({
        url: '/b/form/edit',
        method: 'post',
        data
    })
}
export const getFormDetail = (data) => {
    return request({
        url: '/b/form/get_detail',
        method: 'post',
        data
    })
}
export const getTextCheck = (data) => {
    return request({
        url: '/b/form/text_check',
        method: 'post',
        data
    })
}
export const getFormData = (data) => {
    return request({
        url: '/b/form/data/get',
        method: 'post',
        data
    })
}
export const exportFormData = (data) => {
    return request({
        url: '/b/form/data/export',
        method: 'post',
        data
    })
}
