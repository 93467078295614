
import { useEffect, useState } from 'react';
import css from './index.module.css'
import { Image, Button, Popconfirm, message } from 'antd';
import { onDelete } from '../../../../api/common'

import { getCarousel } from '../../../../api/source'
import { AddCampus } from './news_edit';
import { MyTable } from '../../../../components/table'
import icon from '../../../../assets/image/space.png'
import { PlusOutlined } from '@ant-design/icons'
export const CarouselSourcePage = () => {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)

    const columns = [
      
        {
            title: '轮播图',
            key: 'image',
            render: (recode) => {
                return recode.image ? <Image
                    width={200}
                    style={{ objectFit: 'cover', borderRadius: '5px' }}
                    src={recode.image}
                /> : <img src={icon} alt="" style={{ width: '200px', borderRadius: '5px' }} />
            }
        },
        {
            title: '标题',
            dataIndex: 'title',
        },
        {
            title: '页面路径',
            dataIndex: 'path',
        },
        {
            title: '跳转方式',
            dataIndex: 'mode_name',
        },
        {
            title: '排序',
            dataIndex: 'sort',
        },
        {
            title: '操作',
            key: 'action',
            width: 168,
            render: (record) => (
                <div style={{
                    display: 'flex',
                    columnGap: '10px',
                    height: '40px',
                    alignItems: 'center',
                    color: '#388bff',
                    cursor: 'pointer'
                }} >
                    <AddCampus
                        title={'编辑'}
                        value={record}
                        getData={getData}>
                        <span>编辑</span>
                    </AddCampus>

                    <Popconfirm
                        title="删除"
                        description="确认删除该项数据?"
                        onConfirm={() => { confirm(record) }}
                        okText="确定"
                        showCancel={false}
                    >
                        <span>删除</span>
                    </Popconfirm>
                </div>

            ),
        },
    ];
    const confirm = async (record) => {
        const { errcode } = await onDelete({
            ids: [record.id],
            option: 'carousel'
        })
        if (errcode === 0) {
            message.success('删除成功');
            getData()
        } else {
            message.error('删除失败');
        }
    };
    const getData = async () => {
        const { data } = await getCarousel()
        setList(data)
        setTotal(data.total)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>轮播图</h4>
                <AddCampus title='新建' getData={getData}>
                    <Button type="primary" icon={<PlusOutlined />}>新建</Button>
                </AddCampus>
            </div>

            <MyTable columns={columns}
                dataSource={list}
                y={300}
                total={total}
                per_page={10}>
            </MyTable>
        </div>

    )
}
export default CarouselSourcePage
