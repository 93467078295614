
// 统一删除接口
import request from '../utils/http'

export const onDelete = (data) => {
    return request({
        url: '/b/resource_delete',
        method: 'post',
        data
    })
}

// 统一上传图片
export const onUploadImage = (data) => {
    return request({
        url: '/b/upload/image',
        method: 'post',
        data
    })
}

// oss统一上传
export const onAliyunOssUpload = (data) => {
    return request({
        url: '/b/oss_signature',
        method: 'post',
        data
    })
}

// 栏目管理
export const getColumn = (data) => {
    return request({
        url: '/b/content/column/get_list',
        method: 'post',
        data
    })
}

export const addColumn = (data) => {
    return request({
        url: '/b/content/column/add',
        method: 'post',
        data
    })
}
export const getColumnDetail = (data) => {
    return request({
        url: '/b/content/column/get_detail',
        method: 'post',
        data
    })
}

export const editColumn = (data) => {
    return request({
        url: '/b/content/column/edit',
        method: 'post',
        data
    })
}
