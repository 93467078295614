
import css from '../index.module.css'
import { message, Input, TreeSelect, InputNumber } from 'antd';
import { useState } from 'react';
import { useValidate } from '../../../../../hook/use-validate'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { addProblem, editProblem } from '../../../../../api/problem'


export const AddCampus = ({
    children,
    value,
    title,
    options,
    getData,
}) => {
    const { validate } = useValidate()

    const [params, setParams] = useState({
        question: '',
        belong_column_id: null,
        answer: '',
        sort: 99,
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    const onShowModal = async () => {
        if (value) {
            setParams(value)
        }
        showModal()
    }
    let validateList = [{
        value: 'question',
        msg: '请输入标题',
        required: true
    }, {
        value: 'belong_column_id',
        msg: '请选择类目',
        required: true
    }]


    const onSubmit = () => {
        validate(validateList, params)
            .then(res => {
                if (res) {
                    if (value) {
                        edit()
                    } else {
                        add()
                    }
                }
            })
    }
    const edit = async () => {
        const { errcode } = await editProblem(params)
        if (errcode === 0) {
            message.success('修改成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const add = async () => {
        const { errcode } = await addProblem(params)
        if (errcode === 0) {
            message.success('新建成功')
            getData()
            resetParams()
            handleCancel()
        }
    }

    const handleChange = (val) => {
        setParams({
            ...params,
            belong_column_id: val
        })
    }

    const resetParams = () => {
        setParams({
            question: '',
            belong_column_id: null,
            answer: '',
            sort: 99,
        })
    }
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={800}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.inputBox}>
                        <div className={css.label}>姓名：</div>
                        <Input placeholder="请输入"
                            value={params.question}
                            onChange={(e) => { onChange(e.target.value, 'question') }}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>类目：</div>
                        <TreeSelect
                            style={{
                                width: '268px',
                            }}
                            value={params.belong_column_id}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="请选择"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(value) => { handleChange(value) }}
                            treeData={options}
                        />
                    </div>
                    <div className={`${css.inputBox} ${css.inputBox1}`}>
                        <div className={css.label}>答案：</div>
                        <Input.TextArea style={{
                            height: 160,
                            resize: 'none',
                        }}
                            onChange={(e) => { onChange(e.target.value, 'answer') }}
                            value={params.answer}
                            placeholder="请填写" />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>排序：</div>
                        <InputNumber placeholder="请输入" value={params.sort} onChange={(value) => { onChange(value, 'sort') }} />
                    </div>
                </div>
            </MyModal>
        </>
    )
}
