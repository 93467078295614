
// 存储token
const tokenKey = 'user_token'
const navKey = 'subnav_key'
export const setToken = (val) => {
    return window.localStorage.setItem(tokenKey, val)
}

export const getToken = () => {
    return window.localStorage.getItem(tokenKey)
}

export const removeToken = () => {
    return window.localStorage.removeItem(tokenKey)
}

export const setNavKey = (val) => {
    return window.sessionStorage.setItem(navKey, JSON.stringify(val))
}

export const getNavKey = () => {
    return window.sessionStorage.getItem(navKey)
}
