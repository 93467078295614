
import {message} from 'antd'
export const useValidate = () => {
    
    const validate = (list, params) => {
        return new Promise((resolve, reject) => {
            let flag = true 
            list.some(item => {
                if(item.required) {
                    console.log(list, params);
                    if (!params[item.value]) {
                        flag = false
                        message.error(item.msg)
                        return true
                    }
                }
                return false
            })
            resolve(flag)
        })
    }

    return {
        validate
    }
}
