// 常见问题
import request from '../utils/http'

export const getProblem = (data) => {
    return request({
        url: '/b/content/faq/get_list',
        method: 'post',
        data
    })
}

export const addProblem = (data) => {
    return request({
        url: '/b/content/faq/add',
        method: 'post',
        data
    })
}
export const editProblem = (data) => {
    return request({
        url: '/b/content/faq/edit',
        method: 'post',
        data
    })
}
export const getProblemDetail = (data) => {
    return request({
        url: '/b/content/faq/get_detail',
        method: 'post',
        data
    })
}
