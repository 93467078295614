
import css from './index.module.css'
import { useState, useEffect, useRef } from 'react'
import { QuillModule } from '../../../../components/editor'
import { Button, message } from 'antd'
import { getProfile, editProfile } from '../../../../api/department'

export const SchoolProfilePage = () => {
    const childRef = useRef(null)

    const [introduction, setIntroduction] = useState('')
    const changeEditorHtml = (val) => {
        setIntroduction(val)
    }
    const submit = async () => {

        const {errcode} = await editProfile({
            introduction
        })
        if (errcode === 0) {
            message.success('保存成功')
        }
    }
    const getData = async () => {
        const {data} = await getProfile()
        if (data) {
            childRef.current.setData(data)
        }
        setIntroduction(data)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className={css.content}>

            <h4 className={css.title}>学校简介</h4>

            <div className={css.quillBox}>
                <QuillModule
                    ref={childRef}
                    value={introduction}
                    changeEditorHtml={changeEditorHtml}
                ></QuillModule>
            </div>
            <div className={css.btnBox}>
                <Button type="primary" onClick={submit}>保存内容</Button>
            </div>
        </div>
    )
}
export default SchoolProfilePage
