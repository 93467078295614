import { Dropdown, Form, Input } from 'antd';
import css from './index.module.css'
import { EyeOutlined, LogoutOutlined, LockOutlined } from '@ant-design/icons';
import { MyForm } from "../../../../components/form"
import { MyModal } from "../../../../components/modal"
import { useModal } from "../../../../hook/use-modal"
import { removeToken } from "../../../../store/storage"
import { useNavigate } from "react-router-dom"
import icon1 from '../../../../assets/image/header1.png'
import { editPwd } from "../../../../api/user"

export const MyDropdown = (props) => {
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const onLoginOut = () => {
        removeToken()
        navigate('/login')
    }

    const onEditPwd = () => {
        showModal()
        form.resetFields();
    }

    const submit = () => {
        handleCancel()
    
        form.validateFields().then(values => {
            editPwd(values)
        })
    }
    const items = [
        {
            key: '1',
            label: (
                <div style={{ padding: '4px 0' }} onClick={onEditPwd}>修改密码</div>
            ),
            icon: <EyeOutlined />
        },
        {
            type: 'divider',
        },
        {
            key: '2',
            label: (
                <div style={{ padding: '4px 0' }} onClick={onLoginOut}>退出登录</div>
            ),
            icon: <LogoutOutlined />
        },
    ]; 

    return (
        <>
            <Dropdown
                menu={{
                    items,
                }}
            >
                <div className={css.info} onClick={(e) => e.preventDefault()}>
                    <div className={css.avatar}>
                        <img src={icon1} alt="" />
                    </div>
                    <div>{props.school_name}</div>
                </div>
            </Dropdown>

            <MyModal isModalOpen={isModalOpen}
                title="修改密码"
                centered={true}
                handleOk={submit}
                handleCancel={handleCancel}>
                <div className={css.formBox}>
                    <MyForm form={form} name={'editPwd'} style={{ width: '100%' }}>
                        <Form.Item
                            name="current_password"

                            rules={[
                                {
                                    required: true,
                                    message: '原密码不能为空',
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="请输入原密码"
                            />
                        </Form.Item>

                        <Form.Item
                            name="new_password"
                            rules={[
                                {
                                    required: true,
                                    message: '新密码不能为空',
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="请输入新密码"
                            />
                        </Form.Item>
                    </MyForm>
                </div>
            </MyModal>
        </>
    )
}
