
import css from '../index.module.css'
import { message, Input, TreeSelect, InputNumber } from 'antd';
import { useState } from 'react';
import { useValidate } from '../../../../../hook/use-validate'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { addPicture, editPicture } from '../../../../../api/content'
import { MyUpload } from '../../../../../components/upload'
import { BatchUpload } from './batch_upload'

export const AddCampus = ({
    children,
    value,
    title,
    options,
    getData,
}) => {
    const { validate } = useValidate()
    const [params, setParams] = useState({
        title: '',
        belong_column_id: null,
        image_names: [],
        sort: 99,
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onShowModal = async () => {
        if (value?.id) {
            console.log(value);
            setParams(value)
        }
        showModal()
    }
    let validateList = [{
        value: 'title',
        msg: '请输入标题',
        required: true
    }, {
        value: 'belong_column_id',
        msg: '请选择类目',
        required: true
    }]
   
       
    const onSubmit = () => {
        validate(validateList, params)
        .then(res => {
            if(res) {

                if (value?.id) {
                    edit()
                } else {
                    add()
                }
            }
        })
    }
    const edit = async () => {
        if (!params?.image_name) return message.error('请先上传图片')
        const { errcode } = await editPicture(params)
        if (errcode === 0) {
            message.success('修改成功')
            getData()
            resetParams()
            handleCancel()
        }
    }
    const add = async () => {
        if (params.image_names.length < 1) return message.error('请先上传图片')
        const { errcode } = await addPicture(params)
        if (errcode === 0) {
            message.success('新建成功')
            getData()
            resetParams()
            handleCancel()
        }
    }

    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    const onBatchChange = (val) => {
        setParams(pre => {
            return {
                ...pre,
                image_names: val
            }
        })
    }
    const handleChange = (val) => {
        setParams({
            ...params,
            belong_column_id: val
        })
    }
    const resetParams = () => {
        setParams({
            title: '',
            belong_column_id: null,
            image_names: [],
            sort: 99,
        })
    }
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={800}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.inputBox}>
                        <div className={css.label}>图片名称：</div>
                        <Input placeholder="请输入"
                           
                            value={params.title}
                            onChange={(e) => { onChange(e.target.value, 'title') }}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>类目：</div>
                        <TreeSelect
                           
                            style={{
                                width: '268px',
                            }}
                            value={params.belong_column_id}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="请选择"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(value) => { handleChange(value) }}
                            treeData={options}
                        />
                    </div>
                    <div className={`${css.inputBox} ${css.inputBox1}`}>
                        <div className={css.label}>缩略图：</div>
                        {
                            value?.id ? 
                            <MyUpload onChange={onChange}
                                url={params?.image_name}
                                type='image_name' /> :  
                            <BatchUpload onChange={onBatchChange} />
                        }

                    </div>
                    <div className={css.inputBox}>
                        <div className={css.inputBox}>
                            <div className={css.label}>排列顺序：</div>
                            <InputNumber placeholder="请输入" value={params.sort} onChange={(value) => { onChange(value, 'sort') }} />
                        </div>
                    </div>
        
                </div>
            </MyModal>
        </>
    )
}
