

/** 
 * 院系专业
 * **/
// 学校简介
import request from '../utils/http'

export const getProfile = () => {
    return request({
        url: '/b/school/introduction/get',
        method: 'post',
    })
}

export const editProfile = (data) => {
    return request({
        url: '/b/school/introduction/edit',
        method: 'post',
        data
    })
}

// 校区位置
export const getCampus = () => {
    return request({
        url: '/b/school/campus/get',
        method: 'post',
    })
}
export const addCampus = (data) => {
    return request({
        url: '/b/school/campus/add',
        method: 'post',
        data
    })
}
export const editCampus = (data) => {
    return request({
        url: '/b/school/campus/edit',
        method: 'post',
        data
    })
}

// 学院管理
export const getCollege = () => {
    return request({
        url: '/b/school/college/get',
        method: 'post',
    })
}
export const addCollege = (data) => {
    return request({
        url: '/b/school/college/add',
        method: 'post',
        data
    })
}
export const editCollege = (data) => {
    return request({
        url: '/b/school/college/edit',
        method: 'post',
        data
    })
}
// 专业列表
export const getMajors = (data) => {
    return request({
        url: '/b/school/major/get_list',
        method: 'post',
        data
    })
}
export const addMajor = (data) => {
    return request({
        url: '/b/school/major/add',
        method: 'post',
        data
    })
}
export const editMajor = (data) => {
    return request({
        url: '/b/school/major/edit',
        method: 'post',
        data
    })
}
export const detaMajor = (data) => {
    return request({
        url: '/b/school/major/get_detail',
        method: 'post',
        data
    })
}
