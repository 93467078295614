/* eslint-disable no-sequences */

import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Quill from 'quill'
import { message } from "antd"
import './video'
import './indent'
import css from './index.module.css'
import axios from 'axios'
export const QuillModule = forwardRef((props, ref) => {
    
    useImperativeHandle(ref, () => {
        return {
            setData: (val) => {
                eidtorRef.current.clipboard.dangerouslyPasteHTML(val);
            }
        }
    })

    const eidtorRef = useRef(null)
    const imgRef = useRef(null)
    const uploadVideo = () => {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.click()
        input.addEventListener('change', (e) => {
            const file = e.target.files[0]
            // if(file) 
            if (file.type.indexOf('video') !== -1) {
                insert(file)
            } else {
                message.error('文件格式不对')
            }
        })
    }

    const insert = async (file) => {
        const data = await submit(file)
        const range = eidtorRef.current.getSelection()
        if (range) {
            eidtorRef.current.insertEmbed(range.index, 'simpleVideo', {
                url: data.host + '/' + data.dir,
                controls: 'controls',
                width: '100%',
                height: '300px'
            })
            eidtorRef.current.setSelection(range.index + 1)
        }
    }
    const postOss = async (url, type) => {
        let arr = url.split(',')
        const mime = type || arr[0].match(/:(.*?);/)[1]
        const bytes = window.atob(arr[1])
        const ab = new ArrayBuffer(bytes.length)
        const ia = new Uint8Array(ab)

        for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i)
        }

        const blob = new Blob([ab], {
            type: mime
        })
        const data = await submit(blob)
        console.log(data);
        imgRef.current.setAttribute('src', data.host + '/' + data.dir)
        props.changeEditorHtml(eidtorRef.current.root.innerHTML)
    }
    const submit = async (file) => {
     
        const index = file.type.indexOf('/')
        const suffix = file.type.substring(index + 1)
        const { data } = await axios.post('/b/oss_signature', {
            suffix
        })
        const formData = new FormData();
        formData.append('key', data.dir);
        formData.append('OSSAccessKeyId', data.accessid);
        formData.append('policy', data.policy);
        formData.append('signature', data.signature);
        formData.append('success_action_status', '200');
        formData.append('file', file);
        try {
            await axios(data.host, {
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            return data
        } catch (error) {
            message.error('文件上传失败')
        }

    } 

    var toolbarOptions = [
        [{ 'size': ['small', false, 'large'] }],  // 字体大小
        ['bold', 'italic', 'underline', 'strike', 'link'], // 加粗/斜体/下划线/删除线
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }], // 首行缩进
        [{ 'color': [] }], // 字体颜色
        ['image', 'video'] // 图片/视频
    ];
    var options = {
        modules: {
            toolbar: {
                container: toolbarOptions,
                // container: '#toolbar',
                handlers: {
                    'video': uploadVideo,
                }
            },
       
        },

        theme: 'snow'
    };
    const onChangeContent = (delta, oldDelta, source) => {

        const root = document.querySelector('#editor-container')
        const imgs = root?.querySelectorAll('img')
        if (imgs && imgs.length > 0) {

            imgs.forEach((item, index) => {
                const url = item.getAttribute('src')
                if (url.indexOf('data:image') === 0) {
                    imgRef.current = item
                    postOss(url)
                } else {
                    props.changeEditorHtml(eidtorRef.current.root.innerHTML)
                }
            })
        } else {
            props.changeEditorHtml(eidtorRef.current.root.innerHTML)
            const range = eidtorRef.current.getSelection()
            if (range) {
                eidtorRef.current.setSelection(range.index + 1)
            }
        }
    }
    useEffect(() => {
        if (!eidtorRef.current) {
            eidtorRef.current = new Quill('#editor-container', options)
            eidtorRef.current.on('text-change', onChangeContent);
        }

        if(props.value) {
            eidtorRef.current.clipboard.dangerouslyPasteHTML(props.value);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={css.richText}>
            <div id="toolbar" className={css.toolbar}></div>
            <div id='editor-container' className={css.container}></div>
        </div>
    )
})
