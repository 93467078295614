
import css from '../index.module.css'
import { message, Button, DatePicker, Table, Image } from 'antd';
import { useState } from 'react';
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { 
    getFormData, 
    exportFormData,
    getFormDetail 
} from '../../../../../api/form'

const { RangePicker } = DatePicker;
export const PreviewFormData = ({
    children,
    value,
    title,
}) => {
    const [list, setList] = useState([])

    const [total, setTotal] = useState(0)

    const [params, setParams] = useState({
        form_id: '',
        start_time: '',
        end_time: ''
    })
    const [columns, setColumns] = useState([])

    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()
    const onShowModal = async () => {
        onFormData()
        setColumnsData()
        showModal()
       
        setParams({
            ...params,
            form_id: value?.id
        })
    }
    const [page, setPage] = useState(1)
    const onFormData = async (page, pageSize) => {
        setPage(page)

        const { data } = await getFormData({
            form_id: value?.id,
            page: page || 1,
            pageSize: pageSize || 10
        })
        setList(
            setData(data.formdata)
        )

        setTotal(data.total)
    }
    
    const setData = (arr) => {
        
        const newArr = []
        
        arr.forEach((item, index) => {
            let obj
            item.data.forEach(val => {
                obj = {...obj, [val.question]: val.answer, key: index + 1 }
            })
            newArr.push(obj)
        })
        return newArr
    }
    const setColumnsData = async () => {
        const { data } = await getFormDetail({
            form_id: value?.id,
        })
       setColumns((prevData) => {
            prevData = [{ title: '序号', dataIndex: 'key', key: 'key' }]
            const arr = data.questions.map((item) => {
                let obj = {
                    title: item.question_title,
                    dataIndex: item.question_title,
                    key: item.question_id,
                }
                if (item.question_type === 6){
                    return {
                        ...obj,
                        render: (text, record) => {
                            return <>
                                {
                                    text.map((items, idx) => {
                                        return <div className={css.image_box} key={idx}>
                                            <Image
                                                height={128}
                                                src={items}
                                            />
                                            {/* <img src={items} alt="" key={idx} /> */}
                                        </div>
                                    })
                                }

                            </>
                            
                            // <div className={css.image_box}>
                            //     {
                            //         text.map((items, idx) => {
                            //             return <img src={items} alt="" key={idx} />
                            //         })
                            //     } 
                            // </div>
                        },
                        width: 328
                    }
                }
                return obj
            })
           return [...prevData, ...arr]
       })
    }
    const onChangeDate = (date, dateString) => {
        setParams({
            ...params,
            start_time: dateString[0],
            end_time: dateString[1]
        })
    }
    const onExport = async () => {
        const {errcode, data} = await exportFormData(params)

        if (errcode === 0) {
            let link = document.createElement('a');
            link.href = data;
            link.download = '表单数据.xlsx';
            link.click();
        } else {
            message.error('导出名单失败')
        }

    }

    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={1280}
                footer={false}
                centered={true}
                handleCancel={() => {
                    handleCancel()
                }}>
                <div className={css.query}>
                    <div>
                        <span>时间：</span>
                        <RangePicker  onChange={onChangeDate} />
                    </div>
                    <Button type="primary" onClick={onExport}>导出</Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: page,
                        total: total,
                        hideOnSinglePage: true,
                        showSizeChanger: false
                    }}
                    onChange={(pagination) => onFormData(pagination.current)}
                    scroll={{
                        x: 750,
                        y: 600,
                    }}
                />
            </MyModal>
        </>
    )
}
