
import {useState, Fragment} from 'react'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { detProfile } from '../../../../../api/apply'
import css from './index.module.css'
import { Image, Input, message, Select } from 'antd';
import { auditProfile } from '../../../../../api/apply'

const { TextArea } = Input;
const Photo = ({value, title}) => {
    const flag = Array.isArray(value)

    const view = (val) => {
        return <div className={css.photo}>
            {
                val ? <Image
                    width='100%'
                    fallback="https://fakeimg.pl/328x168/?retina=1&text=加载失败&font=noto"
                    src={val}
                /> : '未上传'
            } 
        </div>
    }
    return (
        <div className={css.item}>
            <div>{title}</div>
            {
                flag ? value.map((item, index) => {
                    return <Fragment key={index}>{ view(item)}</Fragment>
                }) : view(value)
            }
        </div>
    )
}

export const Process = ({ 
    params, 
    title,
    children,
    getData,
    options }) => {
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const [data, setData] = useState({})

    const [details, setDetails] = useState({
        admission_notice_photo: '',
        application_form_photo: '',
        application_reason: '',
        id_card_back_photo: '',
        id_card_front_photo: '',
        id_number: '',
        name: '',
        phone: '',
        qualification_proof_photo: "",
        support_document_photo: []
       
    })   
    const onShowModal = async () => {
        const { data } = await detProfile(params)
        setDetails(data)
        showModal()
        setData({
            ...params,
            audit_status: data.status,
            failure_reason: data.failure_reason
        })
    }
    const handleChange = (val) => {
        setData(prevData => {
            return {
                ...prevData,
                audit_status: val
            }
        })
    }
    const handleReason = (val) => {
        setData(prevData => {
            return {
                ...prevData,
                failure_reason: val
            }
        })
    }
    const onSubmit = async () => {
        if (data?.audit_status === 3 && data?.failure_reason === '') {
            return message.error('必填')
        }
        const {errcode} = await auditProfile(data)
       
        if (errcode === 0) {
            message.success('修改成功')
            getData()
        }
    
        handleCancel()
    }

    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={800}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    handleCancel()
                }}>
                <div className={css.content}>
                    <div className={css.main}>
                        <ul className={css.box}>
                            <li className={css.row}>
                                <div className={css.title}>姓名</div>
                                <div className={css.record}>{details.name}</div>
                            </li>
                            <li className={css.row}>
                                <div className={css.title}>身份证号</div>
                                <div className={css.record}>{details.id_number}</div>
                            </li>
                            <li className={css.row}>
                                <div className={css.title}>联系电话</div>
                                <div className={css.record}>{details.phone}</div>
                            </li>
                            <li className={css.row}>
                                <div className={css.title}>学院</div>
                                <div className={css.record}>{details.college}</div>
                            </li>
                            <li className={css.row}>
                                <div className={css.title}>专业</div>
                                <div className={css.record}>{details.major}</div>
                            </li>
                            <li className={css.row}>
                                <div className={css.title}>申请原因</div>
                                <div className={css.record}>
                                    {
                                        details.application_reason === 1 ? '当兵' : ''
                                    }
                                    {
                                        details.application_reason === 2 ? '出国' : '' 
                                    }
                                    {
                                        details.application_reason === 3 ? '因病' : ''
                                    }
                                    {
                                        details.application_reason === 4 ? details.other_reason_description : ''
                                    }
                                </div>
                            </li>
                            <li className={css.row}>
                                <div className={css.title}>审核状态</div>
                                <Select
                                    defaultValue={data?.audit_status}
                                    style={{
                                        width: '100%',
                                    }}
       
                                    onChange={(value) => { handleChange(value) }}
                                    options={options}
                                />
                            </li>
                            {
                                data?.audit_status === 3 ? <li className={css.row}>
                                    <div className={css.title}>拒绝原因</div>
                                    <TextArea style={{
                                        height: 200,
                                        resize: 'none',
                                    }}
                                        placeholder="请填写"
                                        value={data?.failure_reason}
                                        onChange={(e) => handleReason(e.target.value)} />
                                </li> : <></>
                            }
                           
                        </ul>
                        <div className={css.photo_list}>
                            <Photo title={data?.application_type === 1 ? '新生保留入学资格申请表' : '新生复学申请表' }  value={details.application_form_photo}></Photo>
                            <Photo title='录取通知书' value={details.admission_notice_photo}></Photo>
                            <Photo title='身份证(正面)' value={details.id_card_front_photo}></Photo>
                            <Photo title='身份证(反面)' value={details.id_card_back_photo}></Photo>
                            <Photo title='证明文件' value={details.support_document_photo}></Photo>
                            {
                                params.application_type === 2 ? 
                                    <Photo title='新生保留入学资格证明' value={details.qualification_proof_photo}></Photo> : <></>
                            }
                        </div>
                    </div>
                </div>
            </MyModal>
        </>
    )
}
