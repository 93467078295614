
import css from '../index.module.css'
import { message, Input, InputNumber, Radio, Select } from 'antd';
import { useState, useEffect } from 'react';

import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { addColumn, editColumn, getColumn, getColumnDetail } from '../../../../../api/common'
import { MyUpload } from '../../../../../components/upload'

export const AddCampus = ({
    children,
    id,
    title,
    getData
}) => {
    const [params, setParams] = useState({
        name: '',
        icon: '',
        level: 1,
        category_key: 1,
        superior_column_id: null,
        sort: 99,
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const [options, setOptions] = useState([])
       
    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    
    const handleValue = (val, type) => {
        if (type !== 'superior_column_id') {
            setParams(pre => {
                return {
                    ...pre,
                    superior_column_id: null
                }
            })
        }
        setParams(pre => {
            return {
                ...pre,
                [type]: Number(val)
            }
        })
    }

    const getOptions = async () => {
        const { data } = await getColumn({
            category_keys: [params.category_key]
        })
        setOptions(() => {
            return data.map(item => {
                return { value: item.id, label: item.name }
            })
        })
    }
    const onShowModal = async () => {
        showModal()
        if (id) {
            const {data} = await getColumnDetail({
                column_id: id
            })
            setParams(data)
        }
       
    }
    
    const onSubmit = async () => {
        if (params.name === '') {
            return message.success('必填')
        }
        if (id) {
            const { errcode, msg } = await editColumn(params)
            if (errcode === 0) {
                message.success('修改成功')
                getData()
            } else {
                message.error(msg)
            }
            
        } else {
            const { errcode, msg } = await addColumn(params)
            if (errcode === 0) {
                message.success('新建成功')
            } else {
                message.error(msg)
            }
            getData()
        }
        resetParams()
        handleCancel()
    }
    const resetParams = () => {
        setParams({
            name: '',
            icon: '',
            level: 1,
            category_key: 1,
            superior_column_id: null,
            sort: 99,
        })
    }

    useEffect(() => {
        getOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.category_key]);
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={720}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.inputBox}>
                        <div className={css.label}>栏目名称：</div>
                        <Input placeholder="请输入" value={params.name} onChange={(e) => { onChange(e.target.value,'name')}} />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>栏目图标：</div>
                        <MyUpload onChange={onChange} url={params.icon} type='icon'></MyUpload>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>类别：</div>
                        <Radio.Group 
                            disabled={id}
                            onChange={(e) => { handleValue(e.target.value, 'category_key') }} 
                            value={params.category_key}>
                            <Radio value={1}>文章</Radio>
                            <Radio value={2}>图片</Radio>
                            <Radio value={3}>视频</Radio>
                        </Radio.Group>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>级别：</div>
                        <Radio.Group 
                            onChange={(e) => { handleValue(e.target.value, 'level') }} 
                            value={params.level}>
                            <Radio value={1}>一级栏目</Radio>
                            <Radio value={2}>二级栏目</Radio>
                        </Radio.Group>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>上级栏目：</div>
                        <Select
                            style={{
                                width: 268,
                            }}
                            disabled={params.level === 1}
                            options={options}
                            placeholder='请选择'
                
                            onChange={(value) => { handleValue(value, 'superior_column_id') }}
                            value={params.superior_column_id}></Select>
                       
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>排列顺序：</div>
                        <InputNumber placeholder="请输入" value={params.sort} onChange={(value) => { onChange(value, 'sort') }} />
                    </div>
                </div>
            </MyModal>
        </>
    )
}
