

import request from '../utils/http'
// 登录
export const loginApi = (data) => {
    return request({
        url: '/b/login',
        method: 'post',
        data
    })
}

