
import css from './index.module.css'
import { Input, DatePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const MyInput = ({
    params,
    label,
    name,
    onChange
}) => {
     return (
        <div className={css.inputBox}>
             <div className={css.label}>{label}</div>
            <Input placeholder="请输入"

                 value={params[name]}
                 onChange={(e) => { onChange(e.target.value, name) }}
            />
        </div>
     )
}

export const FormInfo = ({
    params,
    onChange,
    onChangeDate
}) => {
    
    return (
        <>
            <MyInput params={params}
                onChange={onChange} 
                label='标题'
                name='title'>
            </MyInput>

            <MyInput params={params}
                onChange={onChange}
                label='按钮名称'
                name='submit_alias'>
            </MyInput>

            <MyInput params={params}
                onChange={onChange}
                label='表单提交成功提示语'
                name='end_prompt'>
            </MyInput>

            <div className={css.inputBox}>
                <div className={css.label}>表单有效期</div>
                <RangePicker
                    showTime={{
                        format: 'HH:mm',
                    }}
                    defaultValue={[
                        params.start_time && dayjs(params.start_time, "YYYY-MM-DD HH:mm"),
                        params.end_time && dayjs(params.end_time, "YYYY-MM-DD HH:mm"),
                    ]}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onChangeDate}
                />
            </div>

            <div className={css.inputBox}>
                <div className={css.label}>排序</div>
                <InputNumber placeholder="请输入" 

                    value={params.sort}
                    style={{width: '120px'}}
                    onChange={(value) => { onChange(value, 'sort') }} />
            </div>

            <div className={css.inputBox}>
                <div className={css.label}>表单描述</div>
                <Input.TextArea style={{
                    height: 160,
                    resize: 'none',
                }}
                    onChange={(e) => { onChange(e.target.value, 'description') }}
                    value={params.description}
                    placeholder="请填写" />
            </div>
        </>
    )
}
