
import css from './index.module.css'

import { Input, Select, InputNumber, Button, Radio, Tooltip } from 'antd';
import { PlusOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';

export const FormQuestion = ({
    questions,
    textCheck,
    onChange,
    onAddQuestion,
    onDeleteQuestion,
    onHandleOption,
    onCopyQuestion,
    onDeleteOption,
    onAddOption,
    onCopyOption
}) => {
    const options = [{
        value: 1,
        label: '填空题',
    }, {
        value: 2,
        label: '单选题',
    }, {
        value: 3,
        label: '多选题',
    }, {
        value: 4,
        label: '下拉题',
    }, {
        value: 5,
        label: '地址题',
    }, {
        value: 6,
        label: '图片题',
    }]
    const handleValue = (val, type, index) => onChange(val, type, index)
    
    const addQuestion = () => onAddQuestion()
 
    return (
        <div className={css.content}>
            {
                questions.map((item, index) => {
                    return <div className={css.item} key={index}>
                        <div className={css.header}>
                            <div className={css.handleItem}>
                                <Tooltip title="复制问题">
                                    <Button
                                        type="text"
                                        icon={<CopyOutlined />}
                                        onClick={() => onCopyQuestion(index)}
                                    />
                                </Tooltip>
                                <Tooltip title="删除问题">
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => onDeleteQuestion(index)}
                                    />
                                </Tooltip>
                            </div>

                            <div className={css.selectBox}>
                                <div className={css.inputBox}>
                                    <div className={css.label}>问题类型</div>
                                    <Select
                                        value={item.question_type}
                                        style={{
                                            width: 218,
                                        }}
                                        options={options}
                                        onChange={(value) => { handleValue(value, 'question_type', index) }}
                                    />
                                </div>
                                <div className={css.inputBox}>
                                    <div className={css.label}>文本校验</div>
                                    <Select
                                        value={item.question_text_check}
                                        style={{
                                            width: 218,
                                        }}
                                        onChange={(value) => { handleValue(value, 'question_text_check', index) }}
                                        options={textCheck}
                                    />
                                </div>
                            </div>
                        </div>
                      
                    
                        <div className={css.main}>
                            <div className={css.inputBox}>
                                <div className={css.label}>问题</div>
                                <Input placeholder="请输入"
                                    value={item.question_title}
                                    onChange={(e) => { handleValue(e.target.value, 'question_title', index) }}
                                />
                            </div>


                            <div className={css.inputBox}>
                                <div className={css.label}>描述</div>
                                <Input placeholder="请输入"
                                    value={item.question_description}
                                    onChange={(e) => { handleValue(e.target.value, 'question_description', index) }}
                                />
                            </div>
                            {
                                item.question_type !== 1 && 
                                item.question_type !== 5 &&
                                item.question_type !== 6 ?
                           
                                <div className={css.option_item}>
                                    <div className={css.option_head}>
                                        <div className={css.option_title}>选项</div>
                                        <div className={css.option_sort}>排序</div>
                                    </div>
                                    {
                                        item.question_options.map((v, i) => {
                                            return <div className={css.option_main} key={i}>

                                                <Input placeholder="请输入"
                                                    style={{ width: '300px' }}
                                                    value={v.option_value}
                                                    onChange={(e) => onHandleOption(
                                                        e.target.value, 
                                                        'option_value', 
                                                        index,
                                                        i
                                                    )}
                                                />
                                                <InputNumber placeholder="请输入"
                                                    style={{ width: '120px' }}
                                                    value={v.option_sort}
                                                    onChange={(value) => onHandleOption(
                                                        value,
                                                        'option_sort', 
                                                        index,
                                                        i
                                                    )}
                                                />
                                                <div className={css.handleItem}>
                                                    <Tooltip title="复制该项">
                                                        <Button
                                                            size='small'
                                                            type="text"
                                                            icon={<CopyOutlined />}
                                                            onClick={() => onCopyOption(index, i)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="删除该项">
                                                        <Button
                                                            size='small'
                                                            type="text"
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => onDeleteOption(index, i)}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        }) 
                                    }
                                    <div className={css.addQuestion}>
                                        <Button
                                            type="dashed"
                                            icon={<PlusOutlined />}
                                            style={{
                                                width: '168px'
                                            }}
                                            onClick={() => onAddOption(index)}>新建选项</Button>
                                    </div>
                                </div> : '' 
                            }
                           
                        </div>

                        <div className={css.footer}>
                            <div className={css.inputBox}>
                                <div className={css.label}>是否必填</div>
                                <Radio.Group
                                    value={item.question_is_required}
                                    onChange={(e) => handleValue(e.target.value, 'question_is_required', index)}>
                                    <Radio value={0}>否</Radio>
                                    <Radio value={1}>是</Radio>
                                </Radio.Group>
                            </div>

                            <div className={css.inputBox}>
                                <div className={css.label}>排序</div>
                                <InputNumber placeholder="请输入"
                                    style={{ width: '120px' }}
                                    value={item.question_sort}
                                    onChange={(value) => handleValue(value, 'question_sort', index)}
                                />
                            </div>
                        </div>

                         
                    </div>
                })
            }
        
            <div className={css.addQuestion}>
                <Button  
                    icon={<PlusOutlined />}
                    style={{
                        width: '248px'
                    }}
                    onClick={addQuestion}>新建问题</Button>
            </div>
        </div>
    )
}
