
import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { getUserInfo } from "../../api/user"
import css from './index.module.css'
import icon from '../../assets/image/logo.png'
import { SidebarModule } from "./component/sidebar/sidebar"
import { MyDropdown } from "./component/dropdown"


const Layout = () => {
    const navigate = useNavigate()
    const [data, setData] = useState({
        due_time: '',
        school_name: '',
        username: ''
    })
    const getData = async () => {
        const { data } = await getUserInfo()
        setData(data)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className={css.layout}>
            <div className={css.sidebar}>
                <div className={css.sidebarHead} onClick={() => { navigate('/') }}>
                    <div className={css.iconBox}>
                        <img src={icon} alt="" />
                    </div>
                    <div>
                        招生后台
                    </div>
                </div>
                <div className={css.navbar}>
                    <SidebarModule></SidebarModule>
                </div>
                
            </div>
            <div className={css.content}>
                <div className={css.contentHeader}>
                    <div className={css.headLeft}>
                        欢迎使用邦尼招生管理系统
                    </div>
                    <MyDropdown {...data}></MyDropdown>
                </div>
                
                <div className={css.routeContent}>
                    <div className={css.routeMain}>
                       
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
       
        </div>
    )
}

export default Layout
