
import css from '../index.module.css'
import { message, Input, Radio, Switch, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useValidate } from '../../../../../hook/use-validate'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { editAdvert } from '../../../../../api/source'
import { MyUpload } from '../../../../../components/upload'
const { RangePicker } = DatePicker;
export const AddCampus = ({
    children,
    value,
    title,
    options,
    getData,
}) => {
    const { validate } = useValidate()
    const [params, setParams] = useState({
        id: null,
        image: '',
        is_use: 0,
        mode: 0,
        path: '',
        start_time: '',
        end_time: ''
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onShowModal = async () => {
        setParams(value)
        showModal()
    }
    let validateList = [{
        value: 'image',
        msg: '请上传图片',
        required: true
    }, {
        value: 'start_time',
        msg: '请选择时间',
        required: true
    }, {
        value: 'start_time',
        msg: '请选择时间',
        required: true
    }]
   
       
    const onSubmit = () => {
        validate(validateList, params).then(
            async res => {
                if(res) {
                    const { errcode } = await editAdvert(params)
                    if (errcode === 0) {
                        message.success('修改成功')
                        getData()
                        resetParams()
                        handleCancel()
                    }
                }
            }
        )
    }

    const onChange = (val, type) => {
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    const handleRadio = (e) => {
        const val = e.target.value
        setParams({
            ...params,
            mode: val
        })
    }

    const onChangeDate = (value, dateString) => {
        setParams({
            ...params,
            start_time: dateString[0],
            end_time: dateString[1]
        })
    };
    const handleIsTop = (checked) => {
        setParams({
            ...params,
            is_use: checked ? 1 : 0
        })
    }
    const resetParams = () => {
        setParams({
            id: null,
            image: '',
            is_use: '',
            mode: '',
            path: '',
            start_time: '',
            end_time: ''
        })
    }
    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={600}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    
                    <div className={css.inputBox}>
                        <div className={css.label}>图片：</div>
                        <MyUpload onChange={onChange} url={params.image} type='image'></MyUpload>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>页面路径：</div>
                        <Input placeholder="请输入"
                            value={params.path}
                            onChange={(e) => { onChange(e.target.value, 'path') }}
                        />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>跳转方式：</div>
                        <Radio.Group
                            onChange={handleRadio}
                            value={params.mode}>
                            <Radio value={0}>不跳转</Radio>
                            <Radio value={1}>网页链接</Radio>
                            <Radio value={2}>内部链接</Radio>
                            <Radio value={3}>文档</Radio>
                        </Radio.Group>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>是否使用：</div>
                        <Switch defaultChecked={Boolean(params.is_use)} onChange={handleIsTop} />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>使用时长：</div>
                        <RangePicker
                            showTime={{
                                format: 'HH:mm:ss',
                            }}
                            defaultValue={[
                                params.start_time && dayjs(params.start_time, "YYYY-MM-DD HH:mm:ss"),
                                params.end_time && dayjs(params.end_time, "YYYY-MM-DD HH:mm:ss"),
                            ]}
                            format="YYYY-MM-DD HH:mm:ss"
                            onChange={onChangeDate}
                        />
                    </div>
                   
                </div>
            </MyModal>
        </>
    )
}
