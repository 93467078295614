// 人物风采
import request from '../utils/http'

// 优秀学子/名师风采/知名校友
export const getCharacter = (data) => {
    return request({
        url: '/b/content/character/get',
        method: 'post',
        data
    })
}
export const addCharacter = (data) => {
    return request({
        url: '/b/content/character/add',
        method: 'post',
        data
    })
}
export const editCharacter = (data) => {
    return request({
        url: '/b/content/character/edit',
        method: 'post',
        data
    })
}
