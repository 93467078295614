
import request from '../utils/http'
// 招生计划
export const getPlan = (data) => {
    return request({
        url: '/b/inquiry/plan/get',
        method: 'post',
        data
    })
}

export const exportPlan = (data) => {
    return request({
        url: '/b/inquiry/plan/export',
        method: 'post',
        data
    })
}
export const emptyPlan = (data) => {
    return request({
        url: '/b/inquiry/plan/empty',
        method: 'post',
        data
    })
}


// 历年分数
export const getScores = (data) => {
    return request({
        url: '/b/inquiry/scores/get',
        method: 'post',
        data
    })
}
export const exportScores = (data) => {
    return request({
        url: '/b/inquiry/scores/export',
        method: 'post',
        data
    })
}
export const emptyScores = (data) => {
    return request({
        url: '/b/inquiry/scores/empty',
        method: 'post',
        data
    })
}

// 录取查询
export const getAdmission = (data) => {
    return request({
        url: '/b/inquiry/data_query/get',
        method: 'post',
        data
    })
}
export const emptyAdmission = (data) => {
    return request({
        url: '/b/inquiry/data_query/empty',
        method: 'post',
        data
    })
}
export const exportAdmission = (data) => {
    return request({
        url: '/b/inquiry/data_query/export',
        method: 'post',
        data
    })
}
// 
