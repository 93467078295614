
import { useEffect, useState } from 'react';
import css from './index.module.css'
import { Popconfirm, message, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { getCampus } from '../../../../api/department'
import { onDelete } from '../../../../api/common'
import { AddCampus } from './new_edit';
import { MyTable } from '../../../../components/table';
export const CampusPage = () => {


    const [list, setList] = useState([])
  
    const columns = [
        {
            title: '校区名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '校区位置',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
        },

        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <div style={{
                    display: 'flex',
                    columnGap: '10px',
                    color: '#388bff',
                    cursor: 'pointer'
                }} >
                    <AddCampus data={record} title={'编辑'} getData={getData}>
                        <span>编辑</span> 
                    </AddCampus>
                   
                   
                    <Popconfirm
                        title="删除"
                        description="确认删除该项数据?"
                        onConfirm={() => { confirm(record) }}
                        okText="确定"
                        showCancel={false}
                    >
                        <span>删除</span>
                    </Popconfirm>
                </div>
                
            ),
        },
    ];
    const confirm = async (record) => {
        const { errcode } = await onDelete({
            ids: [record.id],
            option: 'campus'
        })
        if (errcode === 0) {
            message.success('删除成功');
            getData()
        } else {
            message.error('删除失败');
        }
        
    };
    const getData = async () => {
        const { data } = await getCampus()
        setList(() => {
            return data.map((item) => {
                return {...item, key: item.id}
            })
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>校区列表</h4>
                <AddCampus title='新建' getData={getData}>
                    <Button type="primary" icon={<PlusOutlined />}>新建</Button>
                </AddCampus>
            </div>
            <MyTable columns={columns}
                dataSource={list}
                total={list.length}
                y={300}>
            </MyTable>
        </div>
         
    )
}
export default CampusPage
