import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import { onUploadImage } from '../api/common'
import { getToken } from '../store/storage';

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
        message.error('Image must smaller than 10MB!');
    }
    return false
};
export const MyUpload = ({ onChange, url, type }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const handleChange = async (info) => {
        const formData = new FormData()
        formData.append('image', info.file)
        const { data } = await onUploadImage(formData)
        setImageUrl(data)

        setLoading(false);
        onChange(data, type)
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                上传
            </div>
        </div>
    );
    
    useEffect(() => {
        if (url) {
            setImageUrl(url)
        }
    }, [url]);

    return (
        <Upload
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            headers={{
                Authorization: getToken()
            }}
            action='https://api.bonnieschool.com/b/upload/image'
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            {imageUrl ? (
                <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                        width: '100%',
                    }}
                />
            ) : (
                uploadButton
            )}
        </Upload>
    )
}
