
import { useEffect, useState } from 'react';
import css from './index.module.css'
import { Popconfirm, message, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { getColumn, onDelete } from '../../../../api/common'

import { AddCampus } from './new_edit'
export const FamousColumnsPage = () => {

    const [list, setList] = useState([])

    const getData = async () => {
        const { data } = await getColumn({
            category_keys: [4, 5, 6]
        })

        setList(data)
    }

    const confirm = async (id) => {
        const { errcode } = await onDelete({
            ids: [id],
            option: 'column'
        })
        if (errcode === 0) {
            message.success('删除成功');
            getData()
        } else {
            message.error('删除失败');
        }
    }

    const showView = (id) => {
        return <div className={css.btnBox}>
            <AddCampus title='编辑' id={id} getData={getData}>
                <button>编辑</button>
            </AddCampus>
            <Popconfirm
                title="删除"
                description="确认删除该项数据?"
                onConfirm={() => { confirm(id) }}
                okText="确定"
                showCancel={false}
            >
                <button>删除</button>
            </Popconfirm>

        </div>
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>栏目管理</h4>

                <AddCampus title='新建' getData={getData}>
                    <Button type="primary" icon={<PlusOutlined />}>新建</Button>
                </AddCampus>
            </div>

            <div className={css.content}>
                <div>
                    {
                        list.map((item, index) => {
                            return <div className={css.item} key={index}>
                                <div className={css.item_header}>
                                    <div>{item.name}</div>
                                    {
                                        showView(item.id)
                                    }
                                </div>
                                {
                                    item.children.length > 0 ? item.children.map((v, i) => {
                                        return <div className={css.item_child} key={i}>
                                            <div>{v.name}</div>
                                            {
                                                showView(v.id)
                                            }
                                        </div>
                                    }) : ''
                                }
                            </div>
                        })
                    }

                </div>
            </div>

        </div>

    )
}

export default FamousColumnsPage
