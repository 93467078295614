
import css from '../index.module.css'
import { message, Input, InputNumber } from 'antd';
import { useState, useRef } from 'react';
import { QuillModule } from '../../../../../components/editor'
import { MyModal } from '../../../../../components/modal';
import { useModal } from "../../../../../hook/use-modal"
import { MyUpload } from '../../../../../components/upload';
import { addCollege, editCollege } from '../../../../../api/department'
export const AddCampus = ({
    children,
    data,
    title,
    getData
}) => {
    const childRef = useRef(null)
    const [params, setParams] = useState({
        name: '',
        icon: '',
        sort: 99,
        introduction: ''
    })
    const {
        isModalOpen,
        showModal,
        handleCancel
    } = useModal()

    const onChange = (val, type) => {
        console.log(val, type);
        setParams(pre => {
            return {
                ...pre,
                [type]: val
            }
        })
    }
    const changeEditorHtml = (val) => {
        setParams(pre => {
            return {
                ...pre,
                introduction: val
            }
        })
    }
    const onShowModal = () => {
        if (data?.id) {
            setParams(data)
        }
        showModal()
    }
    const onSubmit = async () => {
        if (params.name === '') {
            return message.success('必填')
        }
        if (data?.id) {
            const { errcode } = await editCollege(params)
            if (errcode === 0) {
                message.success('修改成功')
            }
            getData()
        } else {
            const { errcode } = await addCollege(params)
            if (errcode === 0) {
                message.success('新建成功')
            }
            getData()
        }
        resetParams()
        handleCancel()
    }
    const resetParams = () => {
        setParams({
            name: '',
            address: '',
            sort: 99,
            introduction: ''
        })
    }

    return (
        <>
            <div onClick={onShowModal}>
                {children}
            </div>
            <MyModal isModalOpen={isModalOpen}
                title={title}
                isDes={true}
                width={800}
                centered={true}
                handleOk={onSubmit}
                handleCancel={() => {
                    resetParams()
                    handleCancel()
                }}>
                <div className={css.formBox}>
                    <div className={css.inputBox}>
                        <div className={css.label}>学院名称：</div>
                        <Input placeholder="请输入" value={params.name} onChange={(e) => { onChange(e.target.value,'name')}} />
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>学院图片：</div>
                        <MyUpload onChange={onChange} url={data?.icon} type='icon'></MyUpload>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.label}>排列顺序：</div>
                        <InputNumber placeholder="请输入" value={params.sort} onChange={(value) => { onChange(value, 'sort') }} />
                    </div>
                </div>
                <div className={css.quillBox}>
                    <QuillModule
                        ref={childRef}
                        value={params.introduction}
                        changeEditorHtml={changeEditorHtml}
                    ></QuillModule>
                </div>
            </MyModal>
        </>
    )
}
