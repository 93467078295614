
import { useEffect, useState, useRef } from 'react';
import css from './index.module.css'
import { message, Button, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined, ClearOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { getPlan, emptyPlan, exportPlan } from '../../../../api/enrollment'
import { getToken } from '../../../../store/storage';
import { MyTable } from '../../../../components/table';
export const PlanPage = () => {
    const oneEff = useRef(true)
    const [total, setTotal] = useState(0)
    const [columns, setColumns] = useState([
        {
            title: '批次',
            dataIndex: 'batch',
        },
        {
            title: '专业',
            dataIndex: 'major',
        },
        {
            title: '人数',
            dataIndex: 'numbers',
        },

        {
            title: '地区',
            dataIndex: 'region',
        },
        {
            title: '科类',
            dataIndex: 'section',
        },
        {
            title: '年份',
            dataIndex: 'year',
        },
    ])
    const [list, setList] = useState([])

    const getData = async (page, per_page) => {
        const { data } = await getPlan({
            page: page || 1,
            per_page: per_page || 20
        })
        const arr = [...columns, ...data.add_title.map(item => {
            return {
                title: item,
                dataIndex: item,
            }
        })]
        const arr1 = data.plan_list.map((item) => {
            if (JSON.stringify(item.others) !== "{}") {
                return { ...item, ...item.others }
            } else {
                return item
            }
        })
        setColumns(arr)
        setList(arr1)
        setTotal(data.total)
    }
    const props = {
        name: 'file',
        action: 'https://api.bonnieschool.com/b/inquiry/plan/import',
        showUploadList: false,
        headers: {
            'Authorization': getToken() || ""
        },
        onChange(info) {
            let rsp = info.file.response;
            if (info.file.status === 'done') {
                if (rsp.errcode === 0) {
                    message.success("数据导入成功");
                    getData()
                } else if (rsp.errcode === 1) {
                    message.error(rsp.msg);
                } else {
                    console.log(rsp);
                    message.error("导入失败");
                }
            } else if (info.file.status === 'error') {
                message.error("导入失败");
            }
        },
    };
    const clearPlan = async () => {
        const res = await emptyPlan();
        if (res.errcode === 0) {
            message.success("数据清空完成!");
            getData()
        }
    }
    const downloadTemplate = () => {
        let link = document.createElement('a');
        link.href = 'https://asset.bonnieschool.com/file/招生计划导入模版.xlsx';
        link.download = '招生计划导入模版.xlsx';
        link.click();
    }
    const onExport = async () => {
        const {data} = await exportPlan()
        let link = document.createElement('a');
        link.href = data;
        link.download = '招生计划.xlsx';
        link.click();
    }
    useEffect(() => {
        if (oneEff.current) {
            getData()
            oneEff.current = false
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={css.container}>
            <div className={css.header}>
                <h4 className={css.title}>招生计划</h4>

                <div className={css.headRight}>
                    <Button icon={<CloudDownloadOutlined />} onClick={downloadTemplate}>下载模板</Button>
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>导入</Button>
                    </Upload>

                    <Button icon={<DownloadOutlined />} onClick={onExport}>导出</Button>

                    <Button icon={<ClearOutlined />} onClick={clearPlan}>清空</Button>
                </div>
            </div>
            <MyTable columns={columns}
                dataSource={list}
                total={total}
                per_page={20}
                y={300}
                getData={getData}>
            </MyTable>
        </div>
         
    )
}
export default PlanPage
